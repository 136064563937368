<template>
    <v-container fluid>
        <v-data-table :headers="headers" :items="items">
            <template v-slot:top>
                <v-toolbar flat>
                    <v-toolbar-title>Configuración</v-toolbar-title>
                    <v-divider class="mx-4" inset vertical></v-divider>
                    <v-spacer></v-spacer>
                </v-toolbar>
            </template>
            <template v-slot:[`item.action_edit`]="{ item }">
                <v-icon @click="verDetalles(item)"> fas fa-edit </v-icon>
            </template>
        </v-data-table>

        <v-dialog v-model="dialog" width="1000" persistent>
            <v-card>
                <v-card-text>
                    <v-row>
                        <v-col cols="12">
                            <v-data-table v-model="tipo_doc_fiscales_seleccionados" :headers="headers_tipo_docs"
                                :items="items_tipo_docs" item-key="nombre" show-select>
                                <template v-slot:[`item.correlativo`]="{ item }">
                                    <v-edit-dialog :return-value.sync="item.correlativo">
                                        {{ item.correlativo }}
                                        <template v-slot:input>
                                            <v-text-field v-model="item.correlativo" label="Editar"
                                                single-line></v-text-field>
                                        </template>
                                    </v-edit-dialog>
                                </template>
                            </v-data-table>
                        </v-col>
                    </v-row>
                </v-card-text>
                <v-card-actions>
                    <v-btn color="primary" @click="guardarConfi()">Guardar</v-btn>
                    <v-spacer></v-spacer>
                    <v-btn text @click="cerrarDialog()">Cancelar</v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>

        <v-dialog v-model="dialog_editar" width="500" persistent>
            <v-card>
                <v-card-title></v-card-title>
                <v-card-text>
                    <v-row>
                        <v-col cols="12">
                            <v-text-field v-model="cod" label="Código" outlined dense hide-details
                                readonly></v-text-field>
                        </v-col>
                        <v-col cols="12">
                            <v-text-field v-model="tipo_doc" label="Tipo Doc." outlined dense hide-details
                                readonly></v-text-field>
                        </v-col>
                        <v-col cols="12">
                            <v-text-field v-model="correlativo" label="Correlativo" outlined dense
                                hide-details></v-text-field>
                        </v-col>
                    </v-row>
                </v-card-text>
                <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn text @click="dialog_editar = false">Cancelar</v-btn>
                    <v-btn color="primary" @click="actualizarConfi()">Actualizar</v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>

        <v-snackbar v-model="snackbar" timeout="8000" color="blue" multi-line elevation="24" outlined top right>
            <v-icon>{{ icon_snackbar }}</v-icon>
            {{ snackbar_text }}
        </v-snackbar>
    </v-container>
</template>

<script>
import axios from "axios";
export default {
    data: () => ({
        dialog: false,
        snackbar: false,
        snackbar_text: "Mensaje",
        icon_snackbar: "fa-solid fa-circle-info",
        headers: [
            { text: "Tipo doc fiscal", value: "nombre_doc_fiscal" },
            { text: "Correlativo", value: "correlativo" },
            { text: "Editar", value: "action_edit" },
        ],
        items: [],
        sucursal: "",
        items_configuraciones: "",

        //TIPO DOCS FISCALES
        headers_tipo_docs: [
            { text: "Nombre", value: "nombre" },
            { text: "Correlativo", value: "correlativo" },
        ],
        items_tipo_docs: [],
        tipo_doc_fiscales_seleccionados: [],
        dialog_editar: false,
        id_confi_factura: 0,
        cod: "",
        tipo_doc: "",
        correlativo: "",
    }),
    created() {

    },
    methods: {
        listarConfiguraciones() {
            axios
                .get("api/ConfiFacturas/Listar") 
                .then((response) => {
                    this.items = response.data;
                });
        },

        
        listarTipoDocFiscales() {
            axios.get("api/TipoDocumentoFiscales/Listar").then((response) => {
                this.items_tipo_docs = response.data;
            });
        },
        nuevaConfi() {
            this.dialog = true;

            this.listarTipoDocFiscales();
        },
        guardarConfi() {
            this.tipo_doc_fiscales_seleccionados.forEach((element) => {
                axios.post("api/ConfiFacturas/Crear", {
                    id_sucursal: this.sucursal.id_sucursal,
                    id_tipo_doc_fiscal: element.id_tipo_doc_fiscal,
                    correlativo: element.correlativo,
                });
            });
            this.cerrarDialog();

            this.snackbar_text = "Registros creados";
            this.snackbar = true;
        },
        actualizarConfi() {
            axios
                .put("api/ConfiFacturas/ActualizarCorrelativo", {
                    id_confi_factura: this.id_confi_factura,
                    correlativo: this.correlativo,
                })
                .then(() => {
                    this.cerrarDialogEditar();
                    //this.listarConfiguraciones();

                    this.snackbar_text = "Registro actualizado";
                    this.snackbar = true;
                });
        },
        cerrarDialog() {
            this.dialog = false;

            this.limpiar();
        },
        verDetalles(item) {
            this.id_confi_factura = item.id_confi_factura,
            this.tipo_doc = item.nombre_doc_fiscal;
            this.correlativo = item.correlativo;

            this.dialog_editar = true;
        },
        cerrarDialogEditar() {
            this.dialog_editar = false;

            this.id_confi_factura = 0;
            this.cod = "";
            this.tipo_doc = "";
            this.correlativo = "";
        },
        limpiar() {
            this.tipo_doc_fiscales_seleccionados = [];
            this.items_tipo_docs = [];
            this.sucursal = "";
        },
    },
};
</script>