<template>
    <v-row>
      <v-col cols="12">
        <v-data-table :headers="headers" :items="items" :search="search" class="elevation-3">
          <template v-slot:top>
            <v-toolbar flat>
              <v-toolbar-title>Servicios</v-toolbar-title>
              <v-divider class="mx-4" inset vertical></v-divider>
              <v-spacer></v-spacer>
              <v-text-field v-model="search" append-icon="fas fa-search" label="Busqueda" single-line outlined dense
                hide-details></v-text-field>
              <v-spacer></v-spacer>
              <v-btn color="primary" dark @click="abrirDialog()"> Nuevo </v-btn>
            </v-toolbar>
          </template>
  
          <template v-slot:[`item.activo`]="{ item }">
            <v-chip v-if="item.activo" color="green" dark>SI</v-chip>
            <v-chip v-else color="red" dark>NO</v-chip>
          </template>
  
          <template v-slot:[`item.exento`]="{ item }">
            <v-chip v-if="item.exento" color="green" dark>SI</v-chip>
            <v-chip v-else color="red" dark>NO</v-chip>
          </template>
  
          <template v-slot:[`item.no_sujeto`]="{ item }">
            <v-chip v-if="item.no_sujeto" color="green" dark>SI</v-chip>
            <v-chip v-else color="red" dark>NO</v-chip>
          </template>
  
          <template v-slot:[`item.actions`]="{ item }">
            <v-tooltip left>
              <template v-slot:activator="{ on, attrs }">
                <v-icon class="mr-2" @click="editarItem(item)" v-bind="attrs" v-on="on">
                  far fa-edit
                </v-icon>
              </template>
              <span>Editar</span>
            </v-tooltip>
          </template>
        </v-data-table>
      </v-col>
  
      <v-dialog v-model="dialog" width="500" persistent>
        <v-card>
          <v-card-text>
            <v-form ref="form" v-model="valid" lazy-validation>
  
              <v-col class="mx-3 v-card v-sheet theme--light elevation-0" row no-gutters>
  
                <v-row style="margin-left: -40px;">
  
                  <div class="col-md-4 col-12 mb-n5">
                    <v-text-field v-model="codigo" label="Codigo" outlined dense :rules="rules"></v-text-field>
                  </div>
  
  
                  <div class="col col-12 mb-n5">
                    <v-text-field v-model="nombreservicio" label="Nombre" outlined dense :rules="rules"></v-text-field>
                  </div>
  
  
                  <v-row justify="space-between">
                    <v-col cols="4" class="d-flex justify-center">
                      <v-checkbox v-model="activo" label="Activo"></v-checkbox>
                    </v-col>
                    <v-col cols="4" class="d-flex justify-center">
                      <v-checkbox v-model="exento" label="Exento"></v-checkbox>
                    </v-col>
                    <v-col cols="4" class="d-flex justify-center">
                      <v-checkbox v-model="no_sujeto"  label="No Sujeto"></v-checkbox>
                    </v-col>
                  </v-row>
                  <v-spacer></v-spacer>
                  <v-spacer></v-spacer>
                  
                  <div class="col col-6 mb-n5">
                    <v-text-field v-model="precio" label="Precio" dense :rules="rules" prefix="$"></v-text-field>
                  </div>
                </v-row>
              </v-col>
  
            </v-form>
          </v-card-text>
          <v-card-actions>
            <v-btn color="primary" v-if="bandera == 1" @click="crearServicio()">GUARDAR</v-btn>
            <v-btn color="primary" v-if="bandera == 2" @click="actualizarServicio()">ACTUALIZAR</v-btn>
            <v-spacer></v-spacer>
            <v-btn text @click="cerrarDialog()">CERRAR</v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
      <lottiefile :estado="overlay" />
    </v-row>
  </template>
  
  <script>
  
  import axios from "axios";
  import lottiefile from "../../LottieFiles/Animaciones.vue"
  import moment from "moment"
  
  export default {
    data: () => ({
  
      search: "",
      headers: [
  
        { text: "Código", value: "codigo" },
        { text: "Nombre", value: "nombreservicio" },
        { text: "Activo", value: "activo",  align: "center" },
        { text: "Exento", value: "exento", align: "center" },
        { text: "No Sujeto", value: "no_sujeto",  align: "center" },
        { text: "Precio", value: "precio" },
        { text: "Editar", value: "actions", align: "center", sorteable: false },
      ],
      items: [],
      dialog: false,
      codigo: null,
      nombreservicio: null,
      activo: false,
      exento: false,
      no_sujeto: false,
      precio: 0,
      disabled: null,
      valid: true,
      rules: [(v) => !!v || "El campo es obligatorio"],
      bandera: -1,
      overlay: false
    }),
    created() {
      this.listar();
  
    },
  
    components: {
      lottiefile
    },
  
    methods: {
      listar() {
        this.overlay = true;
        axios
          .get("api/Servicios/Listar")
          .then((response) => {
            console.log(response.data)
            this.items = response.data;
            this.overlay = false;
          }) 
          .catch((error) => {
            this.overlay = false;
            this.alerta("error", "Sucedio algún error")
          });
      },
  
      abrirDialog() {
        this.disabled = true;
        this.bandera = 1;
        this.dialog = true;
      },
  
      cerrarDialog() {
        this.dialog = false;
        this.limpiar();
      },
  
      editarItem(item) {
        console.log(item)
        this.id_servicios = item.id_servicios;
        this.codigo = item.codigo;
        this.nombreservicio = item.nombreservicio;
        this.precio = item.precio;
        this.activo = item.activo;
        this.exento = item.exento;
        this.no_sujeto = item.no_sujeto;
        this.bandera = 2;
        this.dialog = true;
        this.disabled = true;
      },
      limpiar() {
  
        this.codigo = null;
        this.nombreservicio = null;
        this.activo = null;
        this.exento = null;
        this.no_sujeto = null;
        this.precio = null;
  
        this.$refs.form.resetValidation();
      },
  
      crearServicio() {
        this.$refs.form.validate();
        console.log("Información guardada exitosamente:", {});
        this.overlay = true;
        axios
          .post("api/Servicios/Crear", {
            codigo: this.codigo,
            nombreservicio: this.nombreservicio,
            activo: this.activo,
            exento: this.exento,
            no_sujeto: this.no_sujeto,
            precio: this.precio,
          })
          .then(() => {
            console.log("Información guardada exitosamente:", {});
            this.dialog = false;
            this.limpiar();
            this.listar();
            this.alerta("success", "Registro creado con éxito");
            this.overlay = false;
          })
          .catch(() => {
            this.overlay = false;
            this.alerta("error", "Sucedio algún error");
          });
      },
  
      actualizarServicio() {
        this.overlay = true;
        this.$refs.form.validate();
        axios
          .put("api/Servicios/Actualizar", {
            id_servicios: this.id_servicios,
            codigo: this.codigo,
            nombreservicio: this.nombreservicio,
            activo: this.activo,
            exento: this.exento,
            no_sujeto: this.no_sujeto,
            precio: this.precio,
          
          })
          .then(() => {
            console.log("Información actualizada exitosamente:", {});
            this.dialog = false;
            this.limpiar();
            this.listar();
            this.alerta("success", "Registro actualizado con éxito");
            this.overlay = false;
          })
          .catch(() => {
            this.overlay = false;
            this.alerta("error", "Sucedio algún error");
          });
      },
  
  
      alerta(icon, mensaje) {
        const Toast = this.$swal.mixin({
          toast: true,
          position: "top-end",
          showConfirmButton: false,
          timer: 3500,
          timerProgressBar: true,
        });
        Toast.fire({
          icon: icon,
          title: mensaje,
        });
      },
  
  
      validate() {
        this.$refs.form.validate()
      },
  
    },
    watch: {
      exento(newVal) {
        if (newVal) {
          this.no_sujeto = false;
        }
      },
      no_sujeto(newVal) {
        if (newVal) {
          this.exento = false;
        }
      }
    }
  };
  </script>