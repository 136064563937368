<template>
  <v-container fluid>
    <v-data-table :headers="headers" :items="items">
      <template v-slot:top>
        <v-toolbar elevation="0">
          <v-toolbar-title>Configuración SMTP</v-toolbar-title>
          <v-spacer></v-spacer>
          <v-btn color="primary" @click="nuevaConfiguracion()">Nuevo</v-btn>
        </v-toolbar>
      </template>
      <template v-slot:[`item.action_edit`]="{ item }">
        <v-icon @click="editarItem(item)"> fa-solid fa-pen-to-square </v-icon>
      </template>
    </v-data-table>

    <v-dialog v-model="dialog" width="800" persistent>
      <v-card>
        <v-card-title></v-card-title>
        <v-card-text>
          <v-row>
            <v-col cols="12" md="6">
              <v-text-field v-model="servidor" label="Servidor" outlined dense hide-details></v-text-field>
            </v-col>
            <v-col cols="12" md="2">
              <v-text-field v-model="puerto" label="Puerto" outlined dense hide-details></v-text-field>
            </v-col>
            <v-col cols="4">
              <v-text-field v-model="usuario" label="Usuario" outlined dense hide-details></v-text-field>
            </v-col>
            <v-col cols="12" md="6">
              <v-text-field v-model="correo" label="Correo" outlined dense hide-details></v-text-field>
            </v-col>
            <v-col cols="12" md="6">
              <v-text-field v-model="contraseña" label="Contraseña" outlined dense hide-details></v-text-field>
            </v-col>
            <v-col cols="auto">
              <v-checkbox v-model="usa_ssl" label="Usa SSL" hide-details class="mt-0"></v-checkbox>
            </v-col>
          </v-row>
        </v-card-text>
        <v-card-actions>
          <v-btn color="primary" v-if="flag_edit_new == 1" @click="crearRegistro()">Guardar</v-btn>
          <v-btn color="primary" v-if="flag_edit_new == 2" @click="actualizarRegistro()">Actualizar</v-btn>
          <v-spacer></v-spacer>
          <v-btn text @click="cerrarDialog()">Cancelar</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-container>
</template>

<script>
import axios from "axios";

export default {
  data: () => ({ 
    headers: [
      { text: "Servidor", value: "servidor" },
      { text: "Puerto", value: "puerto" },
      { text: "Editar", value: "action_edit", align: "center" },
    ],
    items: [],
    dialog: false,
    id_confi_smtp: 0,
    servidor: "",
    puerto: "",
    usuario: "",
    correo: "",
    contraseña: "",
    usa_ssl: false,
    flag_edit_new: 0,
  }),
  created() {
    this.listar();
  },
  methods: {
    async listar() {
      axios
        .get("api/ConfiSmtp/Listar/")
        .then((response) => {
          this.items = response.data;
        });
    },
    nuevaConfiguracion() {
      this.flag_edit_new = 1;
      this.dialog = true;
    },
    crearRegistro() {
      axios
        .post("api/ConfiSmtp/Crear", {

          servidor: this.servidor,
          puerto: this.puerto,
          usuario: this.usuario,
          correo: this.correo,
          contraseña: this.contraseña,
          ssl: this.usa_ssl,
        })
        .then(() => {
          this.listar();
          this.cerrarDialog();
        });
    },
    actualizarRegistro() {
      axios
        .put("api/ConfiSmtp/Actualizar", {
          id_confi_smtp: this.id_confi_smtp,
          servidor: this.servidor,
          puerto: this.puerto,
          usuario: this.usuario,
          correo: this.correo,
          contraseña: this.contraseña,
          ssl: this.usa_ssl,
        })
        .then(() => {
          this.listar();
          this.cerrarDialog();
        });
    },
    editarItem(item) {
      this.id_confi_smtp = item.id_confi_smtp;
      this.servidor = item.servidor;
      this.puerto = item.puerto;
      this.usuario = item.usuario;
      this.correo = item.correo;
      this.contraseña = item.contraseña;
      this.usa_ssl = item.ssl;

      this.flag_edit_new = 2;
      this.dialog = true;
    },
    cerrarDialog() {
      this.dialog = false;
      this.limpiar();
    },
    limpiar() {
      this.id_confi_smtp = 0;
      this.servidor = "";
      this.puerto = "";
      this.usuario = "";
      this.correo = "";
      this.contraseña = "";
      this.usa_ssl = false;
    },
  },
};
</script>