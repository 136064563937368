<template>
  <v-container fluid>
    <v-row justify="center">
      <!-- ////////////////////////////////////VER EMPRESA/////////////////////////////////////////////////////////////// -->
      <v-col cols="12" class="text-center">
        <v-data-table
          :items="items_empresas"
          :headers="headers_empresas"
          dense
          class="elevation-1"
          disable-pagination
          hide-default-footer
          :search="search"
          :loading="loading"
        >
          <template v-slot:top>
            <v-toolbar elevation="0">
              <v-spacer></v-spacer>
              <v-text-field
                v-model="search"
                label="Buscar"
                outlined
                dense
                hide-details
              ></v-text-field>
              <v-spacer></v-spacer>
              <v-btn color="primary" @click="NuevaEmpresa()">Nuevo</v-btn>
            </v-toolbar>
          </template>
          <template v-slot:[`item.username`]="{ item }">
            <span>{{ recortarCadena(item.username) }}</span>
          </template>
          <template v-slot:[`item.pwd`]="{ item }">
            <span>{{ recortarCadena(item.pwd) }}</span>
          </template>
          <template v-slot:[`item.token`]="{ item }">
            <span>{{ recortarCadena(item.token) }}</span>
          </template>
          <template v-slot:[`item.opciones`]="{ item }">
            <v-icon @click="editarEmpresa(item)">fas fa-edit</v-icon>
          </template>
        </v-data-table>
      </v-col>
      <!-- ////////////////////////////////////NUEVO, EDITAR EMPRESA/////////////////////////////////////////////////// -->
      <v-dialog v-model="dialog_empresa" width="800" persistent scrollable>
        <v-card>
          <v-card-title>
            EMPRESA
            <v-spacer></v-spacer>
            <v-img
              :src="'data:image/png;base64,' + this.logo"
              max-width="100"
              max-height="100"
              contain
            ></v-img>
          </v-card-title>
          <v-card-text>
            <v-row class="mt-2">
              <v-col cols="12" md="6">
                <v-text-field
                  id="result"
                  v-model="codigo"
                  label="Código"
                  outlined
                  dense
                  hide-details
                ></v-text-field>
              </v-col>
              <v-col cols="12" md="6" align-self="center">
                <v-file-input
                  label="Logo"
                  outlined
                  dense
                  id="myFileInput"
                  hide-details
                ></v-file-input>
              </v-col>
              <v-col cols="12">
                <v-text-field
                  v-model="nombre"
                  label="Nombre"
                  outlined
                  dense
                  hide-details
                ></v-text-field>
              </v-col>
              <v-col cols="12">
                <v-text-field
                  v-model="direccion"
                  label="Dirección"
                  outlined
                  dense
                  hide-details
                >
                </v-text-field>
              </v-col>
              <v-col cols="4">
                <v-text-field
                  v-model="registro_fiscal"
                  label="Registro fiscal"
                  outlined
                  dense
                  hide-details
                >
                </v-text-field>
              </v-col>
              <v-col cols="4">
                <v-text-field
                  v-model="telefono"
                  label="Teléfono"
                  outlined
                  dense
                  hide-details
                >
                </v-text-field>
              </v-col>
              <v-col cols="4">
                <v-text-field
                  v-model="nit"
                  label="NIT"
                  outlined
                  dense
                  hide-details
                >
                </v-text-field>
              </v-col>
              <v-col cols="4">
                <v-autocomplete
                  v-model="departamento"
                  :items="items_id_departamentos"
                  item-value="id_departamentos"
                  item-text="nombre"
                  label="Departamento"
                  outlined
                  dense
                  @input="
                    listarMunicipio2(departamento.codigo),
                      listarMunicipios(departamento.codigo)
                  "
                >
                </v-autocomplete>
              </v-col>
              <v-col cols="4">
                <v-autocomplete
                  v-model="id_municipio2"
                  :items="items_id_municipio2"
                  item-value="id_municipio2"
                  item-text="nombre"
                  label="Municipio"
                  outlined
                  dense
                  return-object
                  hide-details
                >
                </v-autocomplete>
              </v-col>
              <v-col cols="4">
                <v-autocomplete
                  v-model="id_municipios"
                  :items="items_id_municipios"
                  item-value="id_municipios"
                  item-text="nombre"
                  label="Distrito"
                  outlined
                  dense
                  return-object
                  hide-details
                >
                </v-autocomplete>
              </v-col>
              <v-col cols="12">
                <v-text-field
                  v-model="giro"
                  label="Giro"
                  outlined
                  dense
                  hide-details
                >
                </v-text-field>
              </v-col>
              <v-col cols="6">
                <v-text-field
                  v-model="correo"
                  label="Correo"
                  outlined
                  dense
                  hide-details
                >
                </v-text-field>
              </v-col>
              <v-col cols="12">
                <v-autocomplete
                  v-model="actividad_economica"
                  :items="items_acti_economica"
                  item-text="nombre"
                  item-value="cod_MH"
                  label="Actividad Económica"
                  outlined
                  dense
                  hide-details
                >
                  <template v-slot:selection="data">
                    {{ data.item.cod_MH.trim() }} {{ data.item.nombre }}
                  </template>
                  <template v-slot:item="data">
                    {{ data.item.cod_MH.trim() }} {{ data.item.nombre }}
                  </template>
                </v-autocomplete>
              </v-col>

              <v-col cols="12" md="6">
                <v-text-field
                  v-model="usuario"
                  label="Usuario"
                  outlined
                  dense
                  hide-details
                ></v-text-field>
              </v-col>
              <v-col cols="12" md="6">
                <v-text-field
                  v-model="contraseña"
                  label="Contraseña"
                  outlined
                  dense
                  hide-details
                ></v-text-field>
              </v-col>
              <v-col cols="12" md="6">
                <v-text-field
                  v-model="ruta_archivo"
                  label="Ruta archivo"
                  outlined
                  dense
                  hide-details
                ></v-text-field>
              </v-col>
              <v-col cols="12" md="6">
                <v-text-field
                  v-model="clave_privada"
                  label="Clave privada"
                  outlined
                  dense
                  hide-details
                ></v-text-field>
              </v-col>
              <v-col cols="12" md="6">
                <v-text-field
                  v-model="token"
                  label="Token"
                  outlined
                  dense
                  hide-details
                ></v-text-field>
              </v-col>
              <v-col cols="12" md="6">
                <v-text-field
                  v-model="expire"
                  label="Expire token"
                  outlined
                  dense
                  hide-details
                ></v-text-field>
              </v-col>
            </v-row>
          </v-card-text>
          <v-card-actions>
            <v-btn
              color="primary"
              @click="CrearEmpresa()"
              v-if="flag_edit_new == 1"
              >Guardar</v-btn
            >
            <v-btn
              color="primary"
              @click="actualizarEmpresa()"
              v-if="flag_edit_new == 2"
              >Actualizar</v-btn
            >
            <v-spacer></v-spacer>
            <v-btn text @click="cerrarDialogEmpresa()">Cancelar</v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </v-row>

    <v-snackbar
      v-model="snackbar"
      timeout="8000"
      color="blue"
      multi-line
      elevation="24"
      outlined
      top
      right
    >
      <v-icon>{{ icon_snackbar }}</v-icon>
      {{ text_snackbar }}
    </v-snackbar>

    <v-overlay v-model="overlay">
      <v-progress-circular indeterminate size="64"></v-progress-circular>
    </v-overlay>
  </v-container>
</template>

<script>
import axios from "axios";
import settings from "../../settings/settings";

export default {
  data: () => ({
    search: "",
    snackbar: false,
    text_snackbar: "Mensaje",
    icon_snackbar: "fa-solid fa-circle-info",
    overlay: false,
    //////////EMPRESA///////////////
    items_empresas: [],
    headers_empresas: [
      { text: "Código", value: "cod_empresa", sortable: true },
      { text: "Nombre", value: "nombre_empresa", sortable: false },
      { text: "Usuario", value: "username", sortable: false },
      { text: "Contraseña", value: "pwd", sortable: false },
      { text: "Token", value: "token", sortable: false },
      { text: "Editar", value: "opciones", align: "center", sortable: false },
    ],

    direccion: "",
    registro_fiscal: "",
    telefono: "",
    nit: "",
    giro: "",
    correo: "",
    items_acti_economica: [],
    actividad_economica: "",
    items_empresas: [],

    items_id_departamentos: [],
    id_municipios: "",
    id_municipio2: "",
    items_id_municipios: [],
    items_id_municipio2: [],
    departamento: "",
    id_municipios: null,
    id_municipio2: null,

    dialog_empresa: false,
    id_empresa: 0,
    codigo: "",
    nombre: "",
    usuario: "",
    contraseña: "",
    ruta_archivo: "",
    clave_privada: "",
    token: "",
    expire: "",
    flag_edit_new: 1,
    nombre_logo: "",
    logo: null,
    fileByteArray: null,

    items: [],
    loading: false,
  }),
  created() {
    this.BusquedaEmpresa();
    this.listarDepartamentos();
    this.listarActividadEconomica();
  },
  methods: {
    listarDepartamentos() {
      axios.get("api/Departamentos/Listar").then((response) => {
        //console.log(response.data);
        this.items_id_departamentos = response.data;
      });
    },

    async listarMunicipios(codigo) {
      var dep = await (
        await axios.get("api/Departamentos/ListarxId/" + codigo)
      ).data;
      
      this.items_id_municipios = await (await axios.get("api/Municipios/Listar/" + dep[0].codigo)).data;
      this.items_id_municipio2 = (await axios.get("api/Municipio2/Listar/" + dep[0].codigo)).data;
    },

    listarMunicipio2(codigo) {
      axios.get("api/Municipio2/Listar/" + codigo).then((response) => {
        this.items_id_municipio2 = response.data;
      });
    },

    BusquedaEmpresa() {
      const id_empresa = 1;
      this.loading = true;
      axios
        .get(`api/UsuariosXEmpresas/ListarXid/${id_empresa}`)
        .then((response) => {
          if (Array.isArray(response.data)) {
            this.items_empresas = response.data;
          } else {
            this.items_empresas = [];
            this.text_snackbar = "Error: respuesta inesperada de la API.";
            this.snackbar = true;
          }
          this.loading = false;
        })
        .catch((error) => {
          this.items_empresas = [];
          this.loading = false;
          this.text_snackbar = "Error en la solicitud: " + error.message;
          this.snackbar = true;
        });
    },

    CrearEmpresa() {
      if (!this.codigo) {
        this.text_snackbar = "Campos Código no puede quedar vacio";
        this.snackbar = true;
        return;
      }

      this.overlay = true;
      axios
        .put("api/ServicioRecepciones/GuardarEmp", {
          cod_empresa: this.codigo,
          nombre_empresa: this.nombre,
          direccion: this.direccion,
          registro_fiscal: this.registro_fiscal,
          telefono: this.telefono,
          nit: this.nit,
          id_departamentos: this.departamento.id_departamentos,
          id_municipios: this.id_municipios.id_municipios,
          id_municipio2: this.id_municipio2.id_municipio2,
          giro: this.giro,
          correo: this.correo,
          codigo_actividad: this.actividad_economica,
          username: this.usuario,
          pwd: this.contraseña,
          ruta_archivo: this.ruta_archivo,
          clave_privada: this.clave_privada,
        })
        .then((response) => {
          this.cerrarDialogEmpresa();
          this.BusquedaEmpresa();
          this.text_snackbar = response.data;
          this.snackbar = true;
          this.overlay = false;
          this.alerta("fa-solid fa-check", response.data);
        })
        .catch((error) => {
          this.snackbar = true;
          this.overlay = false;
          this.alerta("fa-solid fa-xmark", "No se pudo crear el registro");
        });
    },
    async actualizarEmpresa() {
      this.overlay = true;
      axios
        .put("api/DetallesEmpresas/Actualizar", {
          id_empresa: this.id_empresa,
          cod_empresa: this.codigo,
          nombre_empresa: this.nombre,
          direccion: this.direccion,
          registro_fiscal: this.registro_fiscal,
          telefono: this.telefono,
          nit: this.nit,
          id_departamentos:
            this.departamento.id_departamentos == null
              ? this.departamento
              : this.departamento.id_departamentos,
          id_municipios:
            this.id_municipios.id_municipios == null
              ? this.id_municipios
              : this.id_municipios.id_municipios,
          id_municipio2:
            this.id_municipio2.id_municipio2 == null
              ? this.id_municipio2
              : this.id_municipio2.id_municipio2,
          giro: this.giro,
          correo: this.correo,

          codigo_actividad: this.actividad_economica,

          username: this.usuario,
          pwd: this.contraseña,
          ruta_archivo: this.ruta_archivo,
          clave_privada: this.clave_privada,
          token: this.token,
          expire_token: this.expire,
          nombre_logo: this.nombre_logo,
          logo: await this.convertImage(),
        })
        .then(() => {
          this.dialog_empresa = false;
          this.overlay = false;
          this.alerta("fa-solid fa-check", "Actualizado con exito");
          this.cerrarDialogEmpresa();
          //this.BusquedaEmpresa();
        })
        .catch(() => {
          this.overlay = false;
          this.alerta("fa-solid fa-xmark", "No se pudo actualizar el registro");
        });
    },
    NuevaEmpresa() {
      this.flag_edit_new = 1;
      this.dialog_empresa = true;
    },
    editarEmpresa(item) {
      //console.log(item);
      this.flag_edit_new = 2;
      this.id_empresa = item.id_empresa;
      this.codigo = item.cod_empresa;
      this.nombre = item.nombre_empresa;
      this.direccion = item.direccion;
      this.registro_fiscal = item.registro_fiscal;
      this.telefono = item.telefono;
      this.nit = item.nit;
      this.departamento = Number(item.id_departamentos);
      this.id_municipios = Number(item.id_municipios);
      this.id_municipio2 = Number(item.id_municipio2);
      this.giro = item.giro;
      this.correo = item.correo;

      this.actividad_economica = item.codigo_actividad;

      this.usuario = item.username;
      this.contraseña = item.pwd;
      this.ruta_archivo = item.ruta_archivo;
      this.clave_privada = item.clave_privada;
      this.token = item.token;
      this.expire = item.expire_token;
      this.logo = item.logo;
      this.nombre_logo = item.nombre_logo;

      this.listarMunicipios(this.departamento);

      this.dialog_empresa = true;
    },
    cerrarDialogEmpresa() {
      this.dialog_empresa = false;
      this.limpiarVarEmpresas();
    },
    limpiarVarEmpresas() {
      this.codigo = "";
      this.nombre = "";
      this.direccion = "";
      this.registro_fiscal = "";
      this.telefono = "";
      this.nit = "";
      this.departamento = "";
      this.id_municipios = "";
      this.id_municipio2 = "";
      this.giro = "";
      this.correo = "";

      this.actividad_economica = "";

      this.usuario = "";
      this.contraseña = "";
      this.ruta_archivo = "";
      this.clave_privada = "";
      this.token = "";
      this.expire = "";
      this.logo = "";
      this.nombre_logo = "";
    },
    alerta(icon, mensaje) {
      this.icon_snackbar = icon;
      this.text_snackbar = mensaje;
      this.snackbar = true;
    },
    async convertImage() {
      if (document.getElementById("myFileInput").files[0] == undefined) {
        return null;
      }
      let myFile = document.getElementById("myFileInput").files[0];

      this.nombre_logo = myFile.name;
      if (myFile == undefined) {
        return null;
      }
      let byteArray = await this.fileToByteArray(myFile);
      return byteArray;
    },
    fileToByteArray(file) {
      return new Promise((resolve, reject) => {
        try {
          let reader = new FileReader();
          let fileByteArray = [];
          reader.readAsArrayBuffer(file);
          reader.onloadend = (evt) => {
            if (evt.target.readyState == FileReader.DONE) {
              let arrayBuffer = evt.target.result,
                array = new Uint8Array(arrayBuffer);
              for (var i = 0; i < array.length; i++) {
                fileByteArray.push(array[i]);
              }
            }
            resolve(fileByteArray);
          };
        } catch (e) {
          reject(e);
        }
      });
    },
    recortarCadena(item) {
      return item != null ? item.substring(0, 10) + "..." : "...";
    },

    listarActividadEconomica() {
      axios.get("api/ActividadEconomicas/Listar").then((response) => {
        this.items_acti_economica = response.data;
      });
    },
  },
};
</script>