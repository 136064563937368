<template>
  <v-row no-gutters>
    <v-col cols="6" class="pa-0">
      <v-card flat>
        <v-card-title class="font-weight-bold py-0">Novedades</v-card-title>
        <v-card-text class="py-0">
          <v-timeline align-top dense>
            <v-timeline-item
              v-for="(item, i) in items_time_line_t"
              :key="i"
              :color="item.color"
              small
            >
              <v-row>
                <v-col>
                  <div class="body-1 font-weight-bold">{{ item.titulo }}</div>
                  <div class="body-1">{{ item.sub_titulo }}</div>
                  <div class="body-2 mt-1">Se realizo: {{ item.fecha }}</div>
                </v-col>
              </v-row>
            </v-timeline-item>
          </v-timeline>
        </v-card-text>
      </v-card>
    </v-col>
    <v-col cols="6" class="pa-0">
      <v-card flat>
        <v-card-title class="font-weight-bold py-0">Pendientes</v-card-title>
        <v-card-text class="py-0">
          <v-timeline align-top dense>
            <v-timeline-item
              v-for="(item, i) in items_time_line_p"
              :key="i"
              :color="item.color"
              small
            >
              <v-row>
                <v-col>
                  <div class="body-1 font-weight-bold">{{ item.titulo }}</div>
                  <div class="body-1">{{ item.sub_titulo }}</div>
                </v-col>
              </v-row>
            </v-timeline-item>
          </v-timeline>
        </v-card-text>
      </v-card>
    </v-col>

    <v-dialog v-model="dialog" persistent width="500">
      <v-card>
        <v-card-title>Autenticación OneDrive</v-card-title>
        <v-card-text>
          Para que el sistema funcione correctamente es necesario conectarse a
          OneDrive.
          {{ sesionOneDrive }}
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="primary" @click="apiOneAuthCodigoToken()"
            >Conectar</v-btn
          >
          <v-spacer></v-spacer>
          <v-btn text @click="dialog = false">omitir</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-row>
</template>

<script>
import apiOneDrive from "../ApiOneDrive/API";
import apiLocal from "../AccesosToken/Api";

export default {
  data: () => ({
    dialog: false,
    modals: [
      { title: "Arhivo creado", text: "img 1" },
      { title: "Archivo no creado", text: "img 2" },
    ],
    access_token: "",
    items_time_line_t: [
      {
        titulo: "Correciones y mejoras",
        sub_titulo: "",
        fecha: "07/03/2025",
      },
    ],
    items_time_line_p: [
      {
        titulo: "",
        sub_titulo: "",
      },
    ],
  }),
  created() {
    //console.log(this.$store.state.usuario);
  },
  computed: {
    sesionOneDrive() {
      if (this.$store.state.usuario) {
        this.id_usuario = this.$store.state.usuario.id_usuario;
        this.abrirDialog();
      }
    },
  },
  methods: {
    async abrirDialog() {
      const credenciales = await apiLocal.metodos.listarClavesOneDrive(
        this.$store.state.usuario.id_usuario
      );

      this.access_token = await apiLocal.metodos.getItemObject(
        "access_token",
        credenciales
      );

      if (
        this.access_token == undefined ||
        this.access_token == null ||
        this.access_token == ""
      ) {
        this.dialog = true;
      } else {
        this.dialog = false;
      }
    },
    async apiOneAuthCodigoToken() {
      this.dialog = false;

      await apiOneDrive.metodos.AutenticarUsuario(
        this.$store.state.usuario.id_usuario
      );
    },
    alerta(icon, mensaje) {
      const list = document.createElement("ul");

      this.modals.forEach((element) => {
        const listItem = document.createElement("li");
        listItem.innerHTML =
          "<b>" + element.title + "</b>" + ": " + element.text;
        list.appendChild(listItem);
      });

      var Toast = this.$swal.mixin({
        toast: true,
        position: "top-end",
        width: 500,
        showConfirmButton: false,
        showCloseButton: true,
        closeOnConfirm: false,
        allowOutsideClick: false,
        html: list,
      });

      Toast.fire({
        icon: icon,
        title: mensaje,
      });
    },
  },
};
</script>
