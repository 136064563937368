<template>
  <v-row>
    <v-col cols="12">
      <v-data-table :headers="headers" :items="items" :search="search" class="elevation-3">
        <template v-slot:top>
          <v-toolbar flat>
            <v-toolbar-title>Aseguradoras</v-toolbar-title>
            <v-divider class="mx-4" inset vertical></v-divider>
            <v-spacer></v-spacer>
            <v-text-field v-model="search" append-icon="fas fa-search" label="Busqueda" single-line outlined dense
              hide-details></v-text-field>
            <v-spacer></v-spacer>
            <v-btn color="primary" dark @click="abrirDialog()"> Nuevo </v-btn>
          </v-toolbar>
        </template>
        <template v-slot:[`item.actions`]="{ item }">
          <v-tooltip left>
            <template v-slot:activator="{ on, attrs }">
              <v-icon class="mr-2" @click="editarItem(item)" v-bind="attrs" v-on="on">
                far fa-edit
              </v-icon>
            </template>
            <span>Editar</span>
          </v-tooltip>
        </template>
      </v-data-table>
    </v-col>

    <v-dialog v-model="dialog" width="1000" persistent scrollable>
      <v-card>
        <v-row>
          <v-spacer></v-spacer>
          <v-spacer></v-spacer>
          <v-spacer></v-spacer>
          <v-spacer></v-spacer>
          <v-col>
            <div
              class="v-input v-input--hide-details v-input--is-label-active v-input--is-dirty v-input--dense theme--light v-input--selection-controls v-input--checkbox primary--tex"
              style="margin-left: 10px;">
              <v-checkbox v-model="disabled" :value="true" label="CLIENTE ACTIVO"></v-checkbox>
            </div>
          </v-col>
        </v-row>
        <v-card-text>
          <v-form ref="form" v-model="valid" lazy-validation>

            <v-col class="mx-3 v-card v-sheet theme--light elevation-0" row no-gutters
              style="border: 1px solid black; border-radius: 5px; margin-top: 5px; padding: 40px">

              <v-row style="margin-left: -40px;">

                <div class="col-md-4 col-12 mb-n5">
                  <v-text-field v-model="codigo" label="Codigo" outlined dense :rules="rules"></v-text-field>
                </div>
                <div class="col-md-4 col-12 mb-n5">
                  <v-text-field v-model="descripcion" label="Descripción" outlined dense></v-text-field>
                </div>
                <div class="col-md-4 col-12 mb-n5">
                  <v-text-field v-model="nombre_carpeta" label="Nombre de la Carpeta" outlined dense
                    :rules="rules"></v-text-field>
                </div>

                <div class=" col-md-6 col-12 mb-n5">
                  <v-text-field v-model="fecha_ingreso" type="date" label="Fecha de Ingreso" outlined dense
                    :rules="rules"></v-text-field>
                </div>
                <div class=" col-md-6 col-12 mb-n5">
                  <v-autocomplete v-model="id_tipo_doc_fiscal" :items="items_id_tipo_doc_fiscal"
                    item-value="id_tipo_doc_fiscal" item-text="nombre" label="Tipo de documento fiscal" outlined dense
                    return-object :rules="rules"></v-autocomplete>
                </div>

                <div class="col col-12 mb-n5">
                  <v-text-field v-model="nombre" label="Nombre" outlined dense :rules="rules"></v-text-field>
                </div>

                <div class="col col-12 mb-n5">
                  <v-text-field v-model="razon_social" label="Razón Social" outlined dense></v-text-field>
                </div>

                <div class="col-md-6 col-12 mb-n5">
                  <v-autocomplete v-model="id_departamentos" :items="items_id_departamentos"
                    item-value="id_departamentos" item-text="nombre" label="Departamento" outlined dense return-object
                    @input="listarMunicipios(id_departamentos.codigo)" :rules="rules"></v-autocomplete>
                </div>
                <div class="col-md-6 col-12 mb-n5">
                  <v-autocomplete v-model="id_municipios" :items="items_id_municipios" item-value="id_municipios"
                    item-text="nombre" label="Municipio" outlined dense return-object :rules="rules">
                  </v-autocomplete>
                </div>

                <div class="col col-12 mb-n5">
                  <v-text-field v-model="direccion" label="Dirección" outlined dense :rules="rules"></v-text-field>
                </div>

                <div class="col-md-8 col-12 mb-n5">
                  <v-autocomplete v-model="id_actividad_economica" :items="items_id_actividad_economica"
                    item-value="id_actividad_economica" item-text="nombre" label="Actividad Economica" outlined dense
                    return-object :rules="rules">

                    <template v-slot:selection="index">
                      {{ index.item.cod_MH.trim() }} {{ index.item.nombre }}
                    </template>
                    <template v-slot:item="data">
                      {{ data.item.cod_MH.trim() }} {{ data.item.nombre }}
                    </template>
                  </v-autocomplete>
                </div>
                <div class="col-md-4 col-12 mb-n5">
                  <v-autocomplete v-model="tamaño" :items="items_tamaño" item-text="text" label="Tamaño" outlined dense
                    return-object :rules="rules"></v-autocomplete>
                </div>

                <div class="col-md-4 col-12 mb-n5">
                  <v-text-field v-model="registro" label="Registro" outlined dense :rules="rules"></v-text-field>
                </div>
                <div class="col-md-4 col-12 mb-n5">
                  <v-text-field v-model="correo_1" label="Email" outlined dense :rules="rules"></v-text-field>
                </div>
                <div class="col-md-4 col-12 mb-n5">
                  <v-text-field v-model="correo_2" label="Email 2" outlined dense></v-text-field>
                </div>

                <div class="col-md-8 col-12 mb-n5">
                  <v-text-field v-model="contacto" label="Contacto" outlined dense></v-text-field>
                </div>
                <div class="col-md-4 col-12 mb-n5">
                  <v-text-field v-model="telefono" label="Teléfono" outlined dense :rules="rules"></v-text-field>
                </div>

                <div class="col-md-4 col-12 mb-n5">
                  <v-autocomplete v-model="tipo_persona" :items="items_tipo_persona" item-text="text" label="Tipo"
                    outlined dense :rules="rules" return-object></v-autocomplete>
                </div>

                <div class="col-md-4 col-12 mb-n5">
                  <v-select v-model="id_identificador" :items="items_id_identificador" item-value="id_identificador"
                    item-text="nombre" label="Identificador" outlined dense return-object :rules="rules"></v-select>
                </div>
                
                <div class="col-md-4 col-12 mb-n5">
                  <v-text-field v-model="documento" :items="documento" label="Documento" outlined dense
                    :rules="rules"></v-text-field>
                </div>
              </v-row>
            </v-col>

            <v-col class="mx-3 v-card v-sheet theme--light elevation-0"
              style="border: 1px solid black; border-radius: 5px; margin-top: 1px; margin-top: 10px">
              <v-row justify="center">
                <div class="col-md-auto col-12">
                  <v-checkbox v-model="con_retencion" label="CON RETENCIÓN"></v-checkbox>
                </div>

                <div class="col-md-auto col-12">
                  <v-checkbox v-model="con_percepcion" label="CON PERCEPCIÓN"></v-checkbox>
                </div>

                <div class="col-md-auto col-12">
                  <v-checkbox v-model="exento" :rules="rules" label="EXENTO"></v-checkbox>
                </div>
              </v-row>
            </v-col>

          </v-form>
        </v-card-text>
        <v-card-actions>
          <v-btn color="primary" v-if="bandera == 1" @click="crearAseguradora()">GUARDAR</v-btn>
          <v-btn color="primary" v-if="bandera == 2" @click="actualizarAseguradora()">ACTUALIZAR</v-btn>
          <v-spacer></v-spacer>
          <v-btn text @click="cerrarDialog()">CERRAR</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <lottiefile :estado="overlay" />
  </v-row>
</template>

<script>

import axios from "axios";
import lottiefile from "../../LottieFiles/Animaciones.vue"
import moment from "moment"

export default {
  data: () => ({

    search: "",
    headers: [

      { text: "Código", value: "codigo" },
      { text: "Descripción", value: "descripcion" },
      { text: "Nombre carpeta", value: "nombre_carpeta" },
      { text: "Opciones", value: "actions", align: "center", sorteable: false },
    ],
    items: [],
    dialog: false,
    id_aseguradora: null,
    fecha_ingreso: null,
    id_tipo_doc_fiscal: null,
    codigo: null,
    nombre: null,
    razon_social: null,
    id_departamentos: null,
    id_municipios: null,
    direccion: null,
    id_actividad_economica: null, 
    tamaño: null,
    items_tamaño: [
      { text: "PEQUEÑO", value: "Pequeño" },
      { text: "MEDIANO", value: "Mediano" }, 
      { text: "GRANDE", value: "Grande" },
      { text: "OTROS", value: "Otros" },
    ],
    registro: null,
    correo_1: null,
    correo_2: null,
    contacto: null,
    telefono: null,
    tipo_persona: null,
    items_tipo_persona: [
      { text: "NATURAL", value: "Natural" },
      { text: "JURIDICA", value: "Juridica" },
    ],
    id_identificador: null,
    documento: null,
    items_id_departamentos: [],
    id_municipios: "",
    items_id_municipios: [],
    id_actividad_economica: "",
    items_id_actividad_economica: [],
    id_tipo_doc_fiscal: "",
    items_id_tipo_doc_fiscal: [],
    id_identificador: "",
    items_id_identificador: [],
    con_retencion: false,
    con_percepcion: false,
    exento: false,
    disabled: null,
    valid: true,
    descripcion: null,
    nombre_carpeta: "",
    rules: [(v) => !!v || "El campo es obligatorio"],
    bandera: -1,
    overlay: false
  }),
  created() {
    this.listar();
    this.listarDepartamentos();
    this.listaActividadEconomica();
    this.listaTipoDocumentoFiscal(),
    this.listaIdentificador();
  },

  components: {
    lottiefile
  },

  methods: {
    listar() {
      this.overlay = true;
      axios
        .get("api/Aseguradoras/Listar")
        .then((response) => {
          console.log(response.data)
          this.items = response.data;
          this.overlay = false;
        })
        .catch((error) => {
          this.overlay = false;
          this.alerta("error", "Sucedio algún error")
        });
    },

    abrirDialog() {
      this.disabled = true;
      this.bandera = 1;
      this.dialog = true;
    },

    cerrarDialog() {
      this.dialog = false;
      this.limpiar(); 
    },

    editarItem(item) {
      console.log(item)
      this.id_aseguradora = item.id_aseguradoras;
      this.fecha_ingreso = this.formatoFecha(item.fecha_ingreso);
      this.id_tipo_doc_fiscal = item.id_tipo_doc_fiscal;
      this.codigo = item.codigo;
      this.descripcion = item.descripcion;
      this.nombre_carpeta = item.nombre_carpeta;
      this.nombre = item.nombre;
      this.razon_social = item.razon_social;
      this.id_departamentos = item.id_departamentos;
      this.id_municipios = item.id_municipios;
      this.direccion = item.direccion;
      this.id_actividad_economica = item.id_actividad_economica;
      this.tamaño = item.tamaño;
      this.registro = item.registro;
      this.correo_1 = item.correo_1;
      this.correo_2 = item.correo_2;
      this.contacto = item.contacto;
      this.telefono = item.telefono;
      this.tipo_persona = item.tipo_persona;
      this.id_identificador = item.id_identificador;
      this.documento = item.documento;
      this.con_percepcion = item.con_percepcion;
      this.con_retencion = item.con_retencion;
      this.exento = item.exento;
      this.listarMunicipios(item.cod_departamento);
      this.bandera = 2;
      this.dialog = true;
      this.disabled = true;
    },

    limpiar() {

      this.fecha_ingreso = null;
      this.id_tipo_doc_fiscal = null;
      this.codigo = null;
      this.descripcion = null;
      this.nombre_carpeta = null;
      this.nombre = null;
      this.razon_social = null;
      this.id_departamentos = null;
      this.id_municipios = null;
      this.direccion = null;
      this.id_actividad_economica = null;
      this.tamaño = null;
      this.registro = null;
      this.correo_1 = null;
      this.correo_2 = null;
      this.contacto = null;
      this.telefono = null;
      this.tipo_persona = null;
      this.id_identificador = null;
      this.documento = null;
      this.con_percepcion = null;
      this.con_retencion = null;
      this.exento = null;
      this.$refs.form.resetValidation();
    },

    crearAseguradora() {
      this.$refs.form.validate();
      //console.log("Información guardada exitosamente:", {});
      this.overlay = true;
      axios
        .post("api/Aseguradoras/Crear", {
          fecha_ingreso: this.fecha_ingreso,
          id_tipo_doc_fiscal: this.id_tipo_doc_fiscal.id_tipo_doc_fiscal,
          codigo: this.codigo,
          descripcion: this.descripcion,
          nombre_carpeta: this.nombre_carpeta,
          nombre: this.nombre,
          razon_social: this.razon_social,
          id_departamentos: this.id_departamentos.id_departamentos,
          id_municipios: this.id_municipios.id_municipios,
          direccion: this.direccion,
          id_actividad_economica: this.id_actividad_economica.id_actividad_economica,
          tamaño: this.tamaño.value,
          registro: this.registro,
          correo_1: this.correo_1,
          correo_2: this.correo_2,
          contacto: this.contacto,
          telefono: this.telefono,
          tipo_persona: this.tipo_persona.value,
          id_identificador: this.id_identificador.id_identificador,
          documento: this.documento,
          con_recepcion: this.con_recepcion,
          con_retencion: this.con_retencion,
          exento: this.exento,
        })
        .then(() => {
          console.log("Información guardada exitosamente:", {});
          this.dialog = false;
          this.limpiar();
          this.listar();
          this.alerta("success", "Registro creado con éxito");
          this.overlay = false;
        })
        .catch(() => {
          this.overlay = false;
          this.alerta("error", "Sucedio algún error");
        });
    },

    actualizarAseguradora() {
      this.overlay = true;
      this.$refs.form.validate();
      axios
        .put("api/Aseguradoras/Actualizar", {
          id_aseguradoras: this.id_aseguradora,

          fecha_ingreso: this.fecha_ingreso.fecha_ingreso,
          id_tipo_doc_fiscal: this.id_tipo_doc_fiscal.id_tipo_doc_fiscal == null ? this.id_tipo_doc_fiscal : this.id_tipo_doc_fiscal.id_tipo_doc_fiscal,
          codigo: this.codigo,
          descripcion: this.descripcion,
          nombre_carpeta: this.nombre_carpeta,
          nombre: this.nombre,
          razon_social: this.razon_social,
          id_departamentos: this.id_departamentos.id_departamentos == null ? this.id_departamentos : this.id_departamentos.id_departamentos,
          id_municipios: this.id_municipios.id_municipios == null ? this.id_municipios : this.id_municipios.id_municipios,
          direccion: this.direccion,
          id_actividad_economica: this.id_actividad_economica.id_actividad_economica == null ? this.id_actividad_economica : this.id_actividad_economica.id_actividad_economica,
          tamaño: this.tamaño.value,
          registro: this.registro,
          correo_1: this.correo_1,
          correo_2: this.correo_2,
          contacto: this.contacto,
          telefono: this.telefono,
          tipo_persona: this.tipo_persona.value,
          id_identificador: this.id_identificador.id_identificador == null ? this.id_identificador : this.id_identificador.id_identificador,
          documento: this.documento,
          con_recepcion: this.con_recepcion,
          con_retencion: this.con_retencion,
          exento: this.exento,
          disabled: this.disabled

        })
        .then(() => {
          console.log("Información actualizada exitosamente:", {});
          this.dialog = false;
          this.limpiar();
          this.listar();
          this.alerta("success", "Registro actualizado con éxito");
          this.overlay = false;
        })
        .catch(() => {
          this.overlay = false;
          this.alerta("error", "Sucedio algún error");
        });
    },

    listarDepartamentos() {
      axios.get("api/Departamentos/Listar")
        .then((response) => {
          this.items_id_departamentos = response.data;
        })
    },

    listarMunicipios(codigo) {
      axios.get("api/Municipios/Listar/" + codigo)
        .then((response) => {
          this.items_id_municipios = response.data;
        })
    },

    listaActividadEconomica() {
      axios.get("api/ActividadEconomicas/Listar")
        .then((response) => {
          this.items_id_actividad_economica = response.data;
        })
    },

    listaTipoDocumentoFiscal() {
      axios.get("api/TipoDocumentoFiscales/Listar")
        .then((response) => {
          this.items_id_tipo_doc_fiscal = response.data;
        })
    },

    listaIdentificador() {
      axios.get("api/Identificadores/Listar")
        .then((response) => {
          this.items_id_identificador = response.data;
        })
    },

    alerta(icon, mensaje) {
      const Toast = this.$swal.mixin({
        toast: true,
        position: "top-end",
        showConfirmButton: false,
        timer: 3500,
        timerProgressBar: true,
      });
      Toast.fire({
        icon: icon,
        title: mensaje,
      });
    },

    formatoFecha(fecha) {
      return moment(fecha).format("YYYY-MM-DD");
    },

    validate() {
      this.$refs.form.validate()
    },

  },
  watch: {
    con_retencion(newVal) {
      if (newVal) {
        this.con_percepcion = false;
      }
    },
    con_percepcion(newVal) {
      if (newVal) {
        this.con_retencion = false;
      }
    }
  }
};
</script>