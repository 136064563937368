<template>
  <v-row>
    <v-col cols="12">
      <v-data-table
        :headers="headers"
        :items="items.length ? items : []"
        :search="search"
        dense
        :loading="loading_facturas"
      >
        <template v-slot:top>
          <v-toolbar flat>
            <v-tooltip right>
              <template v-slot:activator="{ on, attrs }">
                <v-icon @click="listarFacturas()" v-bind="attrs" v-on="on">
                  fas fa-sync-alt
                </v-icon>
              </template>
              <span>Actualizar</span>
            </v-tooltip>
            <v-divider class="mx-4" inset vertical></v-divider>
            <v-spacer></v-spacer>
            <v-text-field
              v-model="search"
              label="Buscar"
              outlined
              dense
              hide-details
            ></v-text-field>
            <v-spacer></v-spacer>
            <v-btn color="primary" @click="nuevaFactura()"> Nuevo </v-btn>
          </v-toolbar>
        </template>

        <template v-slot:[`item.fecha`]="{ item }">
          <span> {{ formatoFecha(item.fecha) }}</span>
        </template>

        <template v-slot:[`item.total_pagar`]="{ item }">
          <span> $ {{ Number(item.total_pagar).toFixed(2) }}</span>
        </template>

        <template v-slot:[`item.estatus`]="{ item }">
          <v-tooltip
            left
            color="primary"
            v-if="
              item.sello_recepcion == null &&
              item.resolucion_invalidacion == null
            "
          >
            <template v-slot:activator="{ on, attrs }">
              <v-icon color="primary" v-bind="attrs" v-on="on"
                >far fa-save</v-icon
              >
            </template>
            <span>GUARDADO</span>
          </v-tooltip>

          <v-tooltip
            left
            color="success"
            v-else-if="
              item.sello_recepcion != null &&
              item.resolucion_invalidacion == null
            "
          >
            <template v-slot:activator="{ on, attrs }">
              <v-icon color="success" v-bind="attrs" v-on="on"
                >fas fa-check-circle</v-icon
              >
            </template>
            <span>PROCESADO</span>
          </v-tooltip>

          <v-tooltip
            left
            color="error"
            v-else-if="
              item.resolucion_invalidacion != null &&
              item.resolucion_invalidacion != null
            "
          >
            <template v-slot:activator="{ on, attrs }">
              <v-icon color="error" v-bind="attrs" v-on="on"
                >far fa-times-circle</v-icon
              >
            </template>
            <span>ANULADO</span>
          </v-tooltip>

          <v-tooltip left color="error" v-else-if="item.estatus == 'ERROR'">
            <template v-slot:activator="{ on, attrs }">
              <v-icon color="error" v-bind="attrs" v-on="on"
                >fa-solid fa-circle-exclamation</v-icon
              >
            </template>
            <span>ERROR</span>
          </v-tooltip>
        </template>

        <template v-slot:[`item.action_edit`]="{ item }">
          <v-icon @click="editarFactura(item)"> fas fa-edit </v-icon>
        </template>

        <template v-slot:[`item.action_send_dte`]="{ item }">
          <v-icon
            @click="enviarDTE(item)"
            :disabled="item.sello_recepcion != null"
          >
            fas fa-paper-plane
          </v-icon>
        </template>

        <template v-slot:[`item.action_gen_preview`]="{ item }">
          <v-icon
            @click="vistaPreviaPdf(item)"
            :disabled="item.sello_recepcion != null"
          >
            fas fa-file-pdf
          </v-icon>
        </template>

        <template v-slot:[`item.action_view_dte`]="{ item }">
          <v-icon
            @click="vistaPreviaPdfSello(item)"
            :disabled="item.sello_recepcion == null"
          >
            fas fa-file-pdf
          </v-icon>
        </template>
      </v-data-table>
    </v-col>

    <v-dialog v-model="dialog" persistent width="3000" scrollable>
      <v-card>
        <v-card-text>
          <!-- PRIMERA PARTE CORRELATIVOS-->
          <v-card class="mx-8 my-2">
            <v-card-text>
              <v-row no-gutters>
                <v-col cols="12" md="6" class="pa-1">
                  <v-text-field
                    v-model="numero_control"
                    label="Numero de control"
                    outlined
                    dense
                    hide-details
                    readonly
                  ></v-text-field>
                </v-col>
                <v-col cols="12" md="6" class="pa-1">
                  <v-text-field
                    v-model="codigo_generacion"
                    label="Codígo de generación"
                    outlined
                    dense
                    hide-details
                    readonly
                  ></v-text-field>
                </v-col>
                <v-col cols="12" md="6" class="pa-1">
                  <v-text-field
                    v-model="fecha"
                    label="Fecha y hora"
                    type="datetime-local"
                    outlined
                    dense
                    hide-details
                  ></v-text-field>
                </v-col>
                <v-col cols="12" md="3" class="pa-1">
                  <v-text-field
                    v-model="sello_recepcion"
                    label="Sello de recepción"
                    outlined
                    dense
                    hide-details
                    readonly
                  ></v-text-field>
                </v-col>
                <v-col cols="12" md="3" class="pa-1">
                  <v-text-field
                    v-model="fecha_recepcion"
                    label="Fecha de recepción"
                    type="datetime-local"
                    outlined
                    dense
                    hide-details
                    readonly
                  ></v-text-field>
                </v-col>
              </v-row>
            </v-card-text>
          </v-card>

          <!-- SEGUNDA PARTE SUCARSAL, TIPO DOC-->
          <v-card class="mx-8 mb-2">
            <v-card-text>
              <v-row no-gutters>
                <v-col cols="12" class="pa-1">
                  <v-autocomplete
                    v-model="sucursal"
                    :items="items_sucursales"
                    item-text="descripcion"
                    item-value="id_sucursal"
                    label="Sucursal"
                    auto-select-first
                    outlined
                    dense
                    hide-details
                    @input="listarTipoDocs()"
                  ></v-autocomplete>
                </v-col>
                <v-col cols="12" md="4" class="pa-1">
                  <v-autocomplete
                    v-model="tipo_doc_fiscal"
                    :items="items_tipo_doc_fiscales"
                    item-text="nombre_doc_fiscal"
                    item-value="id_tipo_doc_fiscal"
                    label="Tipo Documento"
                    outlined
                    dense
                    hide-details
                    return-object
                    @change="listarTipoDocs()"
                    @input="listarDocSeleccionado()"
                  ></v-autocomplete>
                </v-col>
                <v-col cols="12" md="4" align-self="center">
                  <v-btn
                    block
                    @click="listarCCF()"
                    :disabled="
                      tipo_doc_fiscal.nombre_doc_fiscal != 'NCR	NOTA DE CREDITO'
                    "
                  >
                    <v-icon left>fa-solid fa-file-invoice-dollar</v-icon>
                    BUSCAR CCF
                  </v-btn>
                </v-col>
                <v-col cols="12" md="4" class="pa-1">
                  <v-autocomplete
                    v-model="tipo_pago"
                    :items="items_tipo_pago"
                    label="Tipo Pago"
                    outlined
                    dense
                    hide-details
                  ></v-autocomplete>
                </v-col>

                <v-col cols="12" md="4" class="pa-1">
                  <v-autocomplete
                    v-model="tipo_operacion"
                    :items="items_tipo_operacion"
                    item-text="tipo_operacion"
                    item-value="id_tipo_operacion"
                    label="Tipo Operación"
                    outlined
                    dense
                    hide-details
                  >
                    <template v-slot:selection="data">
                      {{ data.item.codigo }} {{ data.item.tipo_operacion }}
                    </template>
                    <template v-slot:item="data">
                      {{ data.item.codigo }} {{ data.item.tipo_operacion }}
                    </template>
                  </v-autocomplete>
                </v-col>

                <v-col cols="12" md="4" class="pa-1"></v-col>

                <v-col cols="12" md="4" class="pa-1">
                  <v-autocomplete
                    v-model="tipo_ingreso"
                    :items="items_tipo_ingreso"
                    item-text="descripcion"
                    item-value="id_tipo_ingreso"
                    label="Tipo Ingreso"
                    outlined
                    dense
                    hide-details
                  >
                    <template v-slot:selection="data">
                      {{ data.item.codigo }} {{ data.item.descripcion }}
                    </template>
                    <template v-slot:item="data">
                      {{ data.item.codigo }} {{ data.item.descripcion }}
                    </template>
                  </v-autocomplete>
                </v-col>
              </v-row>
            </v-card-text>
          </v-card>

          <!-- TERCERA PARTE CLIENTE-->
          <v-card class="mx-8 mb-2">
            <v-card-text>
              <v-row no-gutters>
                <v-col cols="12" md="5" class="pa-1">
                  <v-autocomplete
                    v-model="id_aseguradoras"
                    :items="items_id_aseguradoras"
                    item-text="descripcion"
                    item-value="id_aseguradoras"
                    label="Aseguradoras"
                    outlined
                    dense
                    hide-details
                    @input="setAseguradora"
                  />
                </v-col>

                <v-col cols="12" md="3" class="pa-1">
                  <v-text-field
                    v-model="registro"
                    label="Registro"
                    outlined
                    dense
                    hide-details
                    readonly
                  ></v-text-field>
                </v-col>
                <v-col cols="12" md="2" class="pa-1">
                  <v-text-field
                    v-model="identificador"
                    label="Identificador"
                    outlined
                    dense
                    hide-details
                    readonly
                  ></v-text-field>
                </v-col>
                <v-col cols="12" md="2" class="pa-1">
                  <v-text-field
                    v-model="telefono"
                    label="Teléfono"
                    outlined
                    dense
                    hide-details
                    readonly
                  ></v-text-field>
                </v-col>
                <v-col cols="12" md="7" class="pa-1">
                  <v-text-field
                    v-model="nombre"
                    label="Nombre"
                    outlined
                    dense
                    hide-details
                    readonly
                  ></v-text-field>
                </v-col>
                <v-col cols="12" md="2" class="pa-1">
                  <v-text-field
                    v-model="tipo"
                    label="Tipo"
                    outlined
                    dense
                    hide-details
                    readonly
                  ></v-text-field>
                </v-col>
                <v-col cols="12" md="3" class="pa-1">
                  <v-text-field
                    v-model="num_documento"
                    label="Documento"
                    outlined
                    dense
                    hide-details
                    readonly
                  ></v-text-field>
                </v-col>
              </v-row>
            </v-card-text>
          </v-card>

          <!-- TABLA SERVICIOS -->
          <v-card class="mx-8 mb-2">
            <v-card-text>
              <v-data-table
                :headers="headers_servicio"
                :items="items_codigo"
                dense
              >
                <template v-slot:top>
                  <v-toolbar flat>
                    <v-row
                      v-if="$vuetify.breakpoint.mdAndUp"
                      no-gutters
                      justify="space-around"
                      align="center"
                    >
                      <v-col cols="1">
                        <v-text-field
                          v-model="cantidad"
                          label="Cantidad"
                          outlined
                          dense
                          hide-details
                          type="number"
                          :disabled="tipo_doc_fiscal == ''"
                          @input="calculoCantidad"
                        ></v-text-field>
                      </v-col>
                      <v-col cols="1">
                        <v-text-field
                          v-model="codigo"
                          label="Servicios"
                          outlined
                          dense
                          hide-details
                          :disabled="cantidad <= 0 || tipo_doc_fiscal == ''"
                          @input="asignarProducto()"
                        ></v-text-field>
                      </v-col>
                      <v-col cols="auto">
                        <v-btn
                          outlined
                          @click="dialog_servicios = true"
                          :disabled="tipo_doc_fiscal == ''"
                        >
                          <v-icon> fas fa-search </v-icon>
                        </v-btn>
                      </v-col>
                      <v-col cols="5">
                        <v-text-field
                          v-model="descripcion"
                          label="Descripción"
                          outlined
                          dense
                          hide-details
                        ></v-text-field>
                      </v-col>
                      <v-col cols="2">
                        <v-text-field
                          v-model="precio"
                          label="Precio"
                          outlined
                          dense
                          hide-details
                          type="number"
                          :disabled="descripcion == ''"
                          @change="modificarPrecio()"
                        ></v-text-field>
                      </v-col>
                      <v-col cols="1">
                        <v-text-field
                          v-model="descuento_prod"
                          label="%Desc"
                          outlined
                          dense
                          hide-details
                          type="number"
                          :disabled="descripcion == ''"
                          @change="calculoDescuento()"
                        ></v-text-field>
                      </v-col>
                      <v-col cols="1">
                        <v-text-field
                          v-model="total"
                          label="Total"
                          outlined
                          dense
                          hide-details
                          type="number"
                          disabled
                        ></v-text-field>
                      </v-col>
                    </v-row>

                    <v-menu bottom left v-if="$vuetify.breakpoint.smAndDown">
                      <template v-slot:activator="{ on, attrs }">
                        <v-btn color="primary" v-bind="attrs" v-on="on">
                          Detalles
                        </v-btn>
                      </template>
                      <v-card width="500">
                        <v-card-text>
                          <v-row justify="space-around" align="center">
                            <v-col cols="12">
                              <v-text-field
                                v-model="cantidad"
                                label="Cantidad"
                                outlined
                                dense
                                hide-details
                                type="number"
                                :disabled="tipo_doc_fiscal == ''"
                                @input="calculoCantidad"
                              ></v-text-field>
                            </v-col>
                            <v-col cols="12">
                              <v-text-field
                                v-model="codigo"
                                label="Servicio"
                                outlined
                                dense
                                hide-details
                                :disabled="
                                  cantidad <= 0 || tipo_doc_fiscal == ''
                                "
                                @input="asignarProducto()"
                              ></v-text-field>
                            </v-col>
                            <v-col cols="auto">
                              <v-btn
                                outlined
                                @click="dialog_servicios = true"
                                :disabled="tipo_doc_fiscal == ''"
                              >
                                <v-icon>fa-solid fa-magnifying-glass</v-icon>
                              </v-btn>
                            </v-col>
                            <v-col cols="12">
                              <v-text-field
                                v-model="descripcion"
                                label="Descripción"
                                outlined
                                dense
                                hide-details
                              ></v-text-field>
                            </v-col>
                            <v-col cols="12">
                              <v-text-field
                                v-model="precio"
                                label="Precio"
                                outlined
                                dense
                                hide-details
                                type="number"
                                :disabled="descripcion == ''"
                                @change="modificarPrecio()"
                              ></v-text-field>
                            </v-col>
                            <v-col cols="12">
                              <v-text-field
                                v-model="descuento_prod"
                                label="%Desc"
                                outlined
                                dense
                                hide-details
                                type="number"
                                :disabled="descripcion == ''"
                                @change="calculoDescuento()"
                              ></v-text-field>
                            </v-col>
                            <v-col cols="12">
                              <v-text-field
                                v-model="total"
                                label="Total"
                                outlined
                                dense
                                hide-details
                                type="number"
                                disabled
                              ></v-text-field>
                            </v-col>
                          </v-row>
                        </v-card-text>
                      </v-card>
                    </v-menu>
                    <v-spacer></v-spacer>
                    <v-btn
                      class="mx-2"
                      @click="agregarProducto()"
                      :disabled="sello_recepcion != ''"
                    >
                      <v-icon color="green">fas fa-check-square</v-icon>
                    </v-btn>
                  </v-toolbar>
                </template>

                <template v-slot:[`item.precio`]="{ item }">
                  <span> $ {{ Number(item.precio).toFixed(2) }}</span>
                </template>

                <template v-slot:[`item.descuento`]="{ item }">
                  <span> % {{ Number(item.descuento).toFixed(2) }}</span>
                </template>

                <template v-slot:[`item.total`]="{ item }">
                  <span> $ {{ Number(item.total).toFixed(2) }}</span>
                </template>

                <template v-slot:[`item.action_edit`]="{ item }">
                  <v-icon
                    @click="editarProducto(item)"
                    :disabled="sello_recepcion != ''"
                  >
                    fas fa-edit
                  </v-icon>
                </template>

                <template v-slot:[`item.action_delete`]="{ item }">
                  <v-icon
                    class="mr-2"
                    @click="eliminarDetalleFactura(item)"
                    :disabled="sello_recepcion != ''"
                  >
                    fas fa-trash-alt
                  </v-icon>
                </template>
              </v-data-table>
            </v-card-text>
          </v-card>

          <!-- ULTIMA PARTE CALCULOS-->
          <v-row no-gutters justify="center" align="center" class="mx-8">
            <v-card width="150" class="ma-1">
              <v-col cols="12">
                <v-text-field
                  v-model="otros_cargos"
                  label="Otros Cargos"
                  prepend-inner-icon="fa-solid fa-dollar-sign"
                  outlined
                  dense
                  hide-details
                ></v-text-field>
              </v-col>
              <v-col cols="12">
                <v-text-field
                  v-model="anticipo"
                  label="Anticipo"
                  prepend-inner-icon="fa-solid fa-dollar-sign"
                  outlined
                  dense
                  hide-details
                  readonly
                ></v-text-field>
              </v-col>
            </v-card>

            <v-card width="150" class="ma-1">
              <v-col cols="12">
                <v-text-field
                  v-model="calculoSubTotal"
                  label="Sub-total"
                  prepend-inner-icon="fa-solid fa-dollar-sign"
                  outlined
                  dense
                  hide-details
                  readonly
                ></v-text-field>
              </v-col>
              <v-col cols="12">
                <v-text-field
                  v-model="descuento"
                  label="Descuento"
                  prepend-inner-icon="fa-solid fa-dollar-sign"
                  outlined
                  dense
                  hide-details
                  :readonly="flag_descuento"
                ></v-text-field>
              </v-col>
            </v-card>

            <v-card width="150" class="ma-1">
              <v-col cols="12">
                <v-text-field
                  v-model="gravadas"
                  label="Gravadas"
                  prepend-inner-icon="fa-solid fa-dollar-sign"
                  outlined
                  dense
                  hide-details
                  readonly
                ></v-text-field>
              </v-col>
              <v-col cols="12">
                <v-text-field
                  v-model="iva"
                  label="IVA"
                  prepend-inner-icon="fa-solid fa-dollar-sign"
                  outlined
                  dense
                  hide-details
                  readonly
                ></v-text-field>
              </v-col>
            </v-card>

            <v-card width="150" class="ma-1">
              <v-col cols="12">
                <v-text-field
                  v-model="exentas"
                  label="Exentas"
                  prepend-inner-icon="fa-solid fa-dollar-sign"
                  outlined
                  dense
                  hide-details
                  readonly
                ></v-text-field>
              </v-col>
              <v-col cols="12">
                <v-text-field
                  v-model="no_sujetas"
                  label="No sujetas"
                  prepend-inner-icon="fa-solid fa-dollar-sign"
                  outlined
                  dense
                  hide-details
                  readonly
                ></v-text-field>
              </v-col>
            </v-card>

            <v-card width="150" class="ma-1">
              <v-col cols="12">
                <v-text-field
                  v-model="retencion"
                  label="Retención"
                  prepend-inner-icon="fa-solid fa-dollar-sign"
                  outlined
                  dense
                  hide-details
                  readonly
                ></v-text-field>
              </v-col>
              <v-col cols="12">
                <v-text-field
                  v-model="percepcion"
                  label="Percepción"
                  prepend-inner-icon="fa-solid fa-dollar-sign"
                  outlined
                  dense
                  hide-details
                  readonly
                ></v-text-field>
              </v-col>
            </v-card>

            <v-card width="150" class="ma-1">
              <v-col cols="12">
                <v-text-field
                  v-model="calculoTotalOperacion"
                  label="Total operación"
                  prepend-inner-icon="fa-solid fa-dollar-sign"
                  outlined
                  dense
                  hide-details
                  readonly
                ></v-text-field>
              </v-col>
              <v-col cols="12">
                <v-text-field
                  v-model="calculoTotalPagar"
                  label="Total a pagar"
                  prepend-inner-icon="fa-solid fa-dollar-sign"
                  outlined
                  dense
                  hide-details
                  readonly
                ></v-text-field>
              </v-col>
            </v-card>
          </v-row>
        </v-card-text>

        <v-card-actions style="background-color: white">
          <v-btn
            color="primary"
            @click="crearfactura()"
            v-if="flag_new_edit == 1"
            :loading="loading_guardar"
            >Guardar</v-btn
          >
          <v-btn
            color="primary"
            @click="actualizarfactura()"
            v-if="flag_new_edit == 2"
            >Actualizar</v-btn
          >
          <v-btn
            color="red"
            @click="confirmarAnularDocumento()"
            v-if="
              (flag_new_edit == 2 &&
                $store.state.usuario &&
                $store.state.usuario.rol == 'Administrador') ||
              ($store.state.usuario &&
                $store.state.usuario.rol == 'Administrador' &&
                sello_recepcion != '')
            "
            :disabled="sello_recepcion == ''"
            >Anular</v-btn
          >

          <v-btn
            color="red"
            @click="eliminarFactura()"
            v-if="
              flag_new_edit === 2 &&
              (($store.state.usuario &&
                $store.state.usuario.rol === 'Administrador') ||
                ($store.state.usuario &&
                  $store.state.usuario.rol === 'Administrador')) &&
              sello_recepcion === ''
            "
            :loading="loading_eliminar_factura"
            dark
            >Eliminar</v-btn
          >
          <v-spacer></v-spacer>
          <v-btn text @click="cerrarDialog">Cancelar</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-dialog v-model="dialog_id_aseguradoras">
      <v-data-table
        :headers="headers_id_aseguradoras"
        :items="items_id_aseguradoras"
        @dblclick:row="setAseguradora"
      >
        <template v-slot:top>
          <v-toolbar flat>
            <v-toolbar-title>Aseguradoras</v-toolbar-title>
            <v-divider class="mx-4" inset vertical></v-divider>
            <v-spacer></v-spacer>
            <div style="color: black">[Hacer doble click para seleccionar]</div>
          </v-toolbar>
        </template>
      </v-data-table>
    </v-dialog>

    <v-dialog v-model="dialog_servicios">
      <v-data-table
        :headers="headers_servicios"
        :items="items_servicios"
        @dblclick:row="setServicio"
      >
        <template v-slot:top>
          <v-toolbar flat>
            <v-toolbar-title>Servicios</v-toolbar-title>
            <v-divider class="mx-4" inset vertical></v-divider>
            <v-spacer></v-spacer>
            <div style="color: black">[Hacer doble click para seleccionar]</div>
          </v-toolbar>
        </template>
      </v-data-table>
    </v-dialog>

    <v-dialog v-model="dialog_eliminar_servicio" width="500">
      <v-card>
        <v-card-title>¿Esta seguro de eliminar este servicio?</v-card-title>
        <v-card-actions>
          <v-btn color="red" dark @click="confirmarEliminarDetalleFactura()"
            >Eliminar</v-btn
          >
          <v-spacer></v-spacer>
          <v-btn text @click="dialog_eliminar_servicio = false">Cancelar</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-dialog v-model="dialog_tabla_CCF" width="1500" scrollable>
      <v-card>
        <v-card-text class="pa-0">
          <v-data-table
            v-model="items_tipo_doc_ncr_ndb"
            :headers="headers_ccf"
            :items="items_ccf"
            :search="search_ccf"
            :item-class="colorTablaCCF"
            :show-select="tipo_doc_fiscal.nombre == 'NCR	NOTA DE CREDITO'"
            item-key="id_factura"
            single-select
          >
            <template v-slot:top>
              <v-toolbar flat>
                <v-toolbar-title>Facturas</v-toolbar-title>
                <v-divider class="mx-4" inset vertical></v-divider>
                <v-spacer></v-spacer>
                <v-text-field
                  v-model="search_ccf"
                  label="Buscar"
                  outlined
                  dense
                  hide-details
                ></v-text-field>
                <v-spacer></v-spacer>
              </v-toolbar>
            </template>

            <template
              v-slot:[`item.data-table-select`]="{ item, isSelected, select }"
            >
              <v-simple-checkbox
                :value="isSelected"
                :disabled="fechaLimiteDebitoCredito(item)"
                @input="select($event)"
              ></v-simple-checkbox>
            </template>

            <template v-slot:[`item.actions`]="{ item }">
              <v-button @click="testCheck(item)"> Edit </v-button>
            </template>

            <template v-slot:[`item.action_add_servicio`]="{ item }">
              <v-btn
                v-if="tipo_doc_fiscal.nombre == 'NCR	NOTA DE CREDITO'"
                icon
                @click="listarServiciosDocNCR(item)"
                elevation="3"
                color="black"
              >
                <v-icon>fa-solid fa-check</v-icon>
              </v-btn>
            </template>

            <template v-slot:[`item.fecha`]="{ item }">
              <span>{{ formatoFecha(item.fecha) }}</span>
            </template>

            <template v-slot:[`item.gravadas`]="{ item }">
              <span> $ {{ item.gravadas.toFixed(2) }}</span>
            </template>

            <template v-slot:[`item.iva`]="{ item }">
              <span> $ {{ item.iva.toFixed(2) }}</span>
            </template>

            <template v-slot:[`item.total_pagar`]="{ item }">
              <span> $ {{ item.total_pagar.toFixed(2) }}</span>
            </template>
          </v-data-table>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn text @click="dialog_tabla_CCF = false">Cerrar</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-dialog v-model="dialog_tabla_detalles_fac" width="1500" persistent>
      <v-card>
        <v-data-table
          v-model="items_codigo"
          :headers="headers_detalles_fac"
          :items="items_detalles_fac"
          :search="search_detalles_fac"
          item-key="id_detalle_factura"
          show-select
          :item-class="colorTablaDetalles"
        >
          <template v-slot:top>
            <v-toolbar flat>
              <v-toolbar-title>Detalles</v-toolbar-title>
              <v-divider class="mx-4" inset vertical></v-divider>
              <v-spacer></v-spacer>
              <v-text-field
                v-model="search_detalles_fac"
                label="Buscar"
                outlined
                dense
                hide-details
              ></v-text-field>
              <v-spacer></v-spacer>
            </v-toolbar>
          </template>

          <template v-slot:[`item.precio`]="{ item }">
            <span> $ {{ item.precio.toFixed(2) }}</span>
          </template>

          <template v-slot:[`item.descuento`]="{ item }">
            <span> % {{ item.descuento.toFixed(2) }}</span>
          </template>

          <template v-slot:[`item.total`]="{ item }">
            <span> $ {{ item.total.toFixed(2) }}</span>
          </template>
        </v-data-table>
        <v-card-actions>
          <v-btn color="primary" @click="agregarServiciosDocNCR()"
            >Aplicar</v-btn
          >
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-dialog v-model="dialog_confirmar_anular" width="500">
      <v-card>
        <v-card-title>¿Esta seguro de anular el documento?</v-card-title>
        <v-card-actions>
          <v-btn color="red" @click="anularFactura()">Confirmar</v-btn>
          <v-spacer></v-spacer>
          <v-btn @click="dialog_confirmar_anular = !dialog_confirmar_anular"
            >Cancelar</v-btn
          >
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-dialog v-model="dialog_preview_report" fullscreen persistent>
      <v-toolbar flat dense>
        <v-toolbar-title> DOCUMENTO </v-toolbar-title>
        <v-spacer></v-spacer>
        <v-icon @click="dialog_preview_report = !dialog_preview_report"
          >far fa-times-circle</v-icon
        >
      </v-toolbar>
      <iframe :src="base64_doc" id="myPDF" frameborder="0" />
    </v-dialog>

    <v-snackbar
      v-model="snackbar"
      timeout="8000"
      color="blue"
      multi-line
      elevation="24"
      outlined
      top
      right
    >
      <v-icon>{{ icon_snackbar }}</v-icon>
      {{ snackbar_text }}
    </v-snackbar>
  </v-row>
</template>

<script>
import settings from "../settings/settings";
import axios from "axios";

export default {
  data: () => ({
    data_file_input: [],
    result_data_facturas: [],

    loading_guardar: false,
    snackbar: false,
    snackbar_text: "Mensaje",
    icon_snackbar: "fa-solid fa-circle-info",
    empresa: "OTROS",
    search: "",
    dialog: false,
    dialog_id_aseguradoras: false,
    headers: [
      { text: "Número Control", value: "numero_control" },
      { text: "Fecha", value: "fecha" },
      { text: "Tipo Documento", value: "tipo_documento_nombre" },
      { text: "Aseguradora", value: "aseguradora_descripcion" },
      { text: "Total", value: "total_pagar" },
      { text: "Estatus", value: "estatus", align: "center" },
      { text: "Enviar DTE", value: "action_send_dte", align: "center" },
      { text: "Ver previa", value: "action_gen_preview", align: "center" },
      { text: "Ver DTE", value: "action_view_dte", align: "center" },
      { text: "Editar", value: "action_edit", align: "center" },
    ],
    items: [],
    id_factura: 0,
    id_usuario: 0,
    id_tipo_doc_fiscal: 0,
    dialog_eliminar_servicio: false,

    //CLIENTES
    id_aseguradoras: 0,
    headers_id_aseguradoras: [
      { text: "Aseguradora", value: "codigo", sorteable: false },
      { text: "Nombre", value: "nombre", sorteable: false },
      { text: "Registro", value: "registro", sorteable: false },
      {
        text: "Identificador",
        value: "identificador",
        sorteable: false,
      },
      { text: "Teléfono", value: "telefono", sorteable: false },
      { text: "Tipo", value: "tipo_persona", sorteable: false },
    ],
    items_id_aseguradoras: [],
    id_aseguradoras_exento: false,
    id_aseguradoras_retencion: false,
    id_aseguradoras_percepcion: false,
    id_aseguradoras_tamaño: "",
    id_aseguradoras_extranjero: false,
    //DTE-00-00000000-000000000000000
    numero_control: "DTE-00-00000000-000000000000000",
    codigo_generacion: "",
    fecha: "",
    sello_recepcion: "",
    items_facturas_ndb: [],
    fecha_recepcion: "",
    nombre: "",
    registro: "",
    tipo_ingreso: "",
    items_tipo_ingreso: [],
    tipo_operacion: "",
    items_tipo_operacion: [],
    identificador: "",
    telefono: "",
    tipo: "",
    num_documento: "",
    tipo_doc_fiscal: "",
    items_tipo_doc_fiscales: [],
    tipo_pago: "CONTADO",
    items_tipo_pago: ["CONTADO", "CREDITO"],
    contador_tipo_doc_fiscal: 0,
    sello_invalidacion: "",
    //SUCURSAL
    sucursal: "",
    items_sucursales: [],
    //IDENTIFICADORES
    items_identificador: [],
    //TABLA PRODUCTO
    headers_servicio: [
      { text: "Cantidad", value: "cantidad" },
      { text: "Servicios", value: "codigo" },
      { text: "Descripción", value: "descripcion" },
      { text: "Precio", value: "precio" },
      { text: "Descuento", value: "descuento" },
      { text: "Total", value: "total" },
      { text: "Editar", value: "action_edit" },
      { text: "Eliminar", value: "action_delete" },
    ],
    items_codigo: [],
    cantidad: 1,
    //Nuevo
    codigo: "",
    descripcion: "",
    precio: 0,
    descuento_prod: 0,
    monto_descuento: 0,
    total: 0,
    exento: false,
    no_sujeto: false,
    id_detalle_factura: 0,
    cont_prod: 0,

    id: 0,
    item_servicio_eliminar: "",
    //CALCULO DE VALORES
    otros_cargos: 0.0,
    anticipo: 0.0,
    sub_total: 0.0,
    descuento: 0.0,
    gravadas: 0.0,
    iva: 0.0,
    exentas: 0.0,
    no_sujetas: 0.0,
    retencion: 0.0,
    percepcion: 0.0,
    total_operacion: 0.0,
    total_pagar: 0.0,
    flag_descuento: false,
    flag_new_edit: 1,

    //TABLA CCF
    dialog_tabla_CCF: false,
    search_ccf: "",
    headers_ccf: [
      { text: "Servicios", value: "action_add_servicio" },
      { text: "Numero de control", value: "numero_control" },
      { text: "Fecha", value: "fecha" },
      { text: "Tipo documento", value: "tipo_documento_nombre" },
      { text: "Tipo pago", value: "tipo_pago" },
      { text: "Código aseguradora", value: "aseguradora_codigo" },
      { text: "Aseguradora", value: "id_aseguradoras" },
      { text: "Gravadas", value: "gravadas" },
      { text: "IVA", value: "iva" },
      { text: "Total", value: "total_pagar" },
    ],
    items_ccf: [],
    //TABLA DETALLES FACTURA
    dialog_tabla_detalles_fac: false,
    search_detalles_fac: "",
    headers_detalles_fac: [
      { text: "Cantidad", value: "cantidad" },
      { text: "Descripción", value: "descripcion" },
      { text: "Precio", value: "precio" },
      { text: "%Descuento", value: "descuento" },
      { text: "Total", value: "total" },
    ],
    items_detalles_fac: [],
    //DOCUMENTO NCR
    items_tipo_doc_ncr_ndb: [],
    lista_nrc_ndb_guardados: [],
    //SERVICIOS
    dialog_servicios: false,
    headers_servicios: [
      { text: "Servicio", value: "codigo" },
      { text: "Descripción", value: "nombreservicio" },
      { text: "Precio", value: "precio" },
    ],
    items_servicios: [],
    flag_servicio: true,
    dialog_confirmar_anular: false,
    dialog_preview_report: false,
    base64_doc: "",
    loading_facturas: false,
  }),
  created() {
    this.listarFacturas();
    this.listarSucursales();
    this.listarAseguradoras();
    this.listarServicios();
    this.listarTipoDocs();
    this.listarTipoIngreso();
    this.listarTipoOperacion();
  },

  computed: {
    calculoSubTotal() {
      this.sub_total = (
        Number(this.gravadas) +
        Number(this.exentas) +
        Number(this.no_sujetas)
      ).toFixed(2);
      return this.sub_total;
    },
    calculoCantidad() {
      this.total = (Number(this.cantidad) * Number(this.precio)).toFixed(2);
    },
    calculoTotalOperacion() {
      this.total_operacion = (
        Number(this.gravadas) +
        Number(this.iva) -
        Number(this.retencion) +
        Number(this.percepcion) +
        Number(this.exentas) +
        Number(this.no_sujetas)
      ).toFixed(2);
      return this.total_operacion;
    },
    calculoTotalPagar() {
      this.total_pagar = (
        Number(this.gravadas) +
        Number(this.iva) -
        Number(this.retencion) +
        Number(this.percepcion) +
        Number(this.exentas) +
        Number(this.no_sujetas) +
        Number(this.otros_cargos) -
        Number(this.anticipo)
      ).toFixed(2);
      return this.total_pagar;
    },
  },
  methods: {
    formatDateISO(date) {
      const d = new Date(date);
      const isoDate = d.toISOString().slice(0, 19);
      return isoDate;
    },

    enviarDTE(item) {
      //console.log(item);
      this.loading_facturas = true;
      axios
        .post("api/ServicioRecepciones/FirmarDocumento", {
          cod_emp: 9,
          tipoDte: item.tipo_documento_cod,
          idFactura: item.id_factura,
          ambiente: "00",
        })
        .then(() => {
          this.loading_facturas = false;
          this.snackbar_text = "Solicitud enviada";
          this.snackbar = true;
          this.listarFacturas();
        })
        .catch(() => {
          this.loading_facturas = false;
          this.snackbar_text = "Error de solicitud";
          this.snackbar = true;
        });
    },
    vistaPreviaPdf(item) {
      //console.log(item);
      this.loading_facturas = true;

      axios
        .post("api/ServicioRecepciones/VistaPreviaDTE2", {
          cod_emp: this.$store.state.usuario.idUsuario,
          tipoDte: item.tipo_documento_cod,
          idFactura: item.id_factura,
          ambiente: "00",
          es_previo: true,
        })
        .then((response) => {
          //console.log(response.data);
          this.loading_facturas = false;
          this.base64_doc = "data:application/pdf;base64," + response.data;
          this.dialog_preview_report = true;
        })
        .catch(() => {
          this.snackbar_text = "No se pudo generar el reporte";
          this.snackbar = true;
          this.loading_facturas = false;
        });
    },
    vistaPreviaPdfSello(item) {
      this.loading_facturas = true;
      axios
        .post("api/ServicioRecepciones/DescargarFacturaWeb", {
          cod_emp: this.$store.state.usuario.idUsuario,
          tipoDte: item.tipo_documento_cod,
          idFactura: item.id_factura,
          ambiente: "00",
          es_previo: false,
        })
        .then((response) => {
          //console.log(response.data);
          this.loading_facturas = false;
          this.base64_doc = "data:application/pdf;base64," + response.data;
          this.dialog_preview_report = true;
        })
        .catch(() => {
          this.snackbar_text = "No se pudo generar el reporte";
          this.snackbar = true;
          this.loading_facturas = false;
        });
    },

    listarFacturas() {
      this.loading_facturas = true;
      axios
        .get("api/Facturas/Listar")
        .then((response) => {
          //console.log("Respuesta de la API:", response.data);
          if (Array.isArray(response.data)) {
            this.items = response.data;
          } else {
            console.error("La respuesta no es un arreglo:", response.data);
            this.items = [];
          }
          this.loading_facturas = false;
        })
        .catch((error) => {
          console.error("Error al listar facturas:", error);
          this.items = [];
          this.loading_facturas = false;
        });
    },

    listarSucursales() {
      axios.get("api/Sucursales/Listar").then((response) => {
        this.items_sucursales = response.data;
        this.sucursal = response.data[0]?.id_sucursal || "";
        this.listarTipoDocs();
      });
    },

    listarTipoDocs() {
      axios.get("api/ConfiFacturas/Listar").then((response) => {
        this.items_tipo_doc_fiscales = response.data;

        this.llamarCalculo();
      });
    },

    llamarCalculo() {
      for (var i = 0; i < this.items_codigo.length; i++) {
        this.limpiarCalculosCabecera();

        for (var i = 0; i < this.items_codigo.length; i++) {
          //CALCULOS DE DESCUENTO
          this.sumarDescuentos();

          //CALCULOS DE EXENTO Y NO SUJETO
          if (this.id_aseguradoras_exento || this.items_codigo[i].exento) {
            this.calculoExento(this.items_codigo[i].total);
            this.limpiarCamposProductos();
            continue;
          }

          if (this.items_codigo[i].exento) {
            this.calculoExento(this.items_codigo[i].total);
            this.limpiarCamposProductos();
            continue;
          }
          if (this.items_codigo[i].no_sujeto) {
            this.calculoNoSujeto();
            this.limpiarCamposProductos();
            continue;
          }

          if (
            this.tipo_doc_fiscal.nombre == "FAC	FACTURA DE CONSUMIDOR FINAL" ||
            this.tipo_doc_fiscal.tipo_documento_nombre
          ) {
            if (this.id_aseguradoras_retencion) {
              if (this.items_codigo[i].total >= 100) {
                var sin_iva = (this.items_codigo[i].total / 1.13).toFixed(2);
                this.retencion = (
                  Number(this.retencion) +
                  sin_iva * 0.01
                ).toFixed(2);
              }
            }
          } else {
            if (this.id_aseguradoras_retencion) {
              if (this.items_codigo[i].total >= 100) {
                this.retencion = (
                  Number(this.retencion) +
                  Number(this.items_codigo[i].total) * Number(0.01)
                ).toFixed(2);
              }
            }
          }

          if (this.id_aseguradoras_percepcion) {
            if (this.total >= 100) {
              this.percepcion =
                Number(this.percepcion) +
                (Number(this.items_codigo[i].total) * Number(0.01)).toFixed(2);
            }
          }

          this.sumarSubTotales(this.items_codigo[i].total);
          this.calculoGravadas(this.sub_total, this.descuento);

          if (
            this.tipo_doc_fiscal.nombre == "CCF	COMPROBANTE DE CREDITO FISCAL" ||
            this.tipo_doc_fiscal.nombre == "NCR	NOTA DE CREDITO"
          ) {
            this.calculoIva(this.gravadas);
          }
        }
      }
    },
    listarAseguradoras() {
      axios.get("api/Aseguradoras/Listar").then((response) => {
        this.items_id_aseguradoras = response.data;
      });
    },

    nuevaFactura() {
      this.fecha = settings.metodos.formatoFechaHora(new Date());
      this.obtenerGuid();
      this.flag_new_edit = 1;
      this.dialog = true;
    },

    obtenerGuid() {
      axios.get("api/Facturas/GenerarGuid").then((response) => {
        this.codigo_generacion = response.data;
      });
    },
    asignarProducto() {
      axios
        .get(
          "api/Servicios/ObtenerServicioPorCodigo/" +
            this.codigo +
            "/" +
            this.$store.state.usuario.idUsuario
        )
        .then((response) => {
          //console.log(response.data);
          if (this.tipo_doc_fiscal.nombre == "FAC	FACTURA DE CONSUMIDOR FINAL") {
            if (response.data.exento || response.data.no_sujeto) {
              this.precio = (
                Number(response.data.precio) / Number(1.13)
              ).toFixed(2);
            } else {
              this.precio = response.data.precio;
            }
          } else {
            this.precio = (
              Number(response.data.precio) / Number(1.13)
            ).toFixed();
          }
          this.flag_servicio = response.data == "" ? true : false;
          this.descripcion = response.data.nombreservicio;
          this.total = (Number(this.precio) * Number(this.cantidad)).toFixed(2);
          this.exento = response.data.exento;
          this.no_sujeto = response.data.no_sujeto;
        });
    },
    modificarPrecio() {
      this.precio = Number(this.precio).toFixed(2);
      this.total = Number(this.precio).toFixed(2);
    },
    calculoDescuento() {
      this.monto_descuento = 0;
      this.monto_descuento =
        Number(this.cantidad) *
        Number(this.precio) *
        (Number(this.descuento_prod) / 100);
      this.total = (Number(this.total) - Number(this.monto_descuento)).toFixed(
        2
      );
    },
    setAseguradora() {
      const aseguradoraSeleccionada = this.items_id_aseguradoras.find(
        (aseguradora) => aseguradora.id_aseguradoras === this.id_aseguradoras
      );

      if (aseguradoraSeleccionada) {
        this.nombre = aseguradoraSeleccionada.nombre;
        this.registro = aseguradoraSeleccionada.registro;
        this.identificador = aseguradoraSeleccionada.id_identificador;
        this.telefono = aseguradoraSeleccionada.telefono;
        this.tipo = aseguradoraSeleccionada.tipo_persona;
        this.num_documento = aseguradoraSeleccionada.documento;
        this.id_aseguradoras_exento = aseguradoraSeleccionada.exento;
        this.id_aseguradoras_retencion = aseguradoraSeleccionada.con_retencion;
        this.id_aseguradoras_percepcion =
          aseguradoraSeleccionada.con_percepcion;
        this.id_aseguradoras_tamaño = aseguradoraSeleccionada.tamaño;
        this.id_aseguradoras_extranjero = aseguradoraSeleccionada.extranjero;

        this.llamarCalculo();
      } else {
        this.nombre = "";
        this.registro = "";
        this.identificador = "";
        this.telefono = "";
        this.tipo = "";
        this.num_documento = "";
        this.id_aseguradoras_exento = false;
        this.id_aseguradoras_retencion = false;
        this.id_aseguradoras_percepcion = false;
        this.id_aseguradoras_tamaño = "";
        this.id_aseguradoras_extranjero = false;
      }
    },

    listarServicios() {
      axios.get("api/Servicios/Listar").then((response) => {
        //console.log(response.data);
        this.items_servicios = response.data;
      });
    },
    setServicio(event, { item }) {
      if (
        this.tipo_doc_fiscal.nombre == "FAC	FACTURA DE CONSUMIDOR FINAL" ||
        this.tipo_doc_fiscal.nombre == "FEX"
      ) {
        if (item.exento || item.no_sujeto) {
          this.precio = (Number(item.precio) / Number(1.13)).toFixed(2);
        } else {
          this.precio = item.precio;
        }
      } else {
        this.precio = (Number(item.precio) / Number(1.13)).toFixed(2);
      }

      this.codigo = item.codigo;
      this.descripcion = item.nombreservicio;
      this.total = (Number(this.precio) * Number(this.cantidad)).toFixed(2);
      this.exento = item.exento;
      this.no_sujeto = item.no_sujeto;

      // Validación adicional
      if (this.exento || this.id_aseguradoras_exento) {
        this.calculoExento(this.total);
      } else if (this.exento && !this.id_aseguradoras_exento) {
        this.calculoExento(this.total);
      }

      this.dialog_servicios = false;
    },

    agregarProducto() {
      if (!this.id_aseguradoras || !this.tipo_doc_fiscal) {
        this.snackbar_text =
          "Debe de seleccionar el tipo de documento fiscal y la aseguradora";
        this.snackbar = true;
        return;
      }
      if (this.tipo_doc_fiscal.nombre == "FEX") {
        if (this.id_aseguradoras_extranjero == false) {
          this.snackbar_text = "La aseguradora no es extranjero";
          this.snackbar = true;
          return;
        }
      }

      if (!this.codigo) {
        this.snackbar_text = "El campo producto no puede ir vacío";
        this.snackbar = true;
        return;
      }

      if (this.id_detalle_factura == 0) {
        var index = this.items_codigo.findIndex((item) => item.id == this.id);
        if (index != -1) {
          this.editarDetalleServicio(index);
          return;
        }
      } else if (this.id_detalle_factura > 0) {
        var index = this.items_codigo.findIndex(
          (item) => item.id_detalle_factura == this.id_detalle_factura
        );
        this.editarDetalleServicio(index);
        return;
      }

      this.cont_prod++;

      this.sumarDescuentos();

      if (this.id_aseguradoras_exento || this.exento) {
        this.calculoExento(this.total);
      } else if (this.exento && !this.id_aseguradoras_exento) {
        this.calculoExento(this.total);
      }

      this.items_codigo.push({
        id: this.cont_prod,
        id_detalle_factura: -1,
        cantidad: this.cantidad,
        codigo: this.codigo,
        descripcion: this.descripcion,
        precio: this.precio,
        descuento: this.descuento_prod,
        total: this.total,
        exento: this.exento,
        no_sujeto: this.no_sujeto,
      });

      if (this.id_aseguradoras_exento || this.exento) {
        this.calculoExento(this.total);
        this.limpiarCamposProductos();
        return;
      }

      if (this.exento) {
        this.calculoExento(this.total);
        this.limpiarCamposProductos();
        return;
      }
      if (this.no_sujeto) {
        this.calculoNoSujeto();
        this.limpiarCamposProductos();
        return;
      }

      if (
        this.tipo_doc_fiscal.nombre == "FAC	FACTURA DE CONSUMIDOR FINAL" ||
        this.tipo_doc_fiscal.tipo_documento_nombre ==
          "FAC	FACTURA DE CONSUMIDOR FINAL"
      ) {
        if (this.id_aseguradoras_retencion) {
          if (this.total >= 100) {
            var sin_iva = (this.total / 1.13).toFixed(2);
            this.retencion = (Number(this.retencion) + sin_iva * 0.01).toFixed(
              2
            );
          }
        }
      } else {
        if (this.id_aseguradoras_retencion) {
          if (this.total >= 100) {
            this.retencion = (
              Number(this.retencion) +
              Number(this.total) * Number(0.01)
            ).toFixed(2);
          }
        }
      }

      if (this.id_aseguradoras_percepcion) {
        if (this.total >= 100) {
          this.percepcion = (Number(this.total) * Number(0.01)).toFixed(2);
        }
      }

      this.sumarSubTotales(this.total);
      this.calculoGravadas(this.sub_total, this.descuento);

      if (
        this.tipo_doc_fiscal.nombre == "CCF	COMPROBANTE DE CREDITO FISCAL" ||
        this.tipo_doc_fiscal.nombre == "NCR	NOTA DE CREDITO"
      ) {
        this.calculoIva(this.gravadas);
      }

      this.limpiarCamposProductos();
    },

    editarDetalleServicio(index) {
      this.items_codigo[index].cantidad = this.cantidad;
      this.items_codigo[index].codigo = this.codigo;
      this.items_codigo[index].descripcion = this.nombreservicio;
      this.items_codigo[index].precio = this.precio;
      this.items_codigo[index].descuento = this.descuento;
      this.items_codigo[index].total = this.total;
      this.items_codigo[index].exento = this.exento;
      this.items_codigo[index].no_sujeto = this.no_sujeto;

      this.id_detalle_factura = 0;
      this.limpiarCalculosCabecera();

      for (var i = 0; i < this.items_codigo.length; i++) {
        //CALCULOS DE DESCUENTO
        this.sumarDescuentos();

        //CALCULOS DE EXENTO Y NO SUJETO
        if (this.id_aseguradoras_exento || this.items_codigo[i].exento) {
          this.calculoExento(this.items_codigo[i].total);
          this.limpiarCamposProductos();
          continue;
        }

        if (this.items_codigo[i].exento) {
          this.calculoExento(this.items_codigo[i].total);
          this.limpiarCamposProductos();
          continue;
        }
        if (this.items_codigo[i].no_sujeto) {
          this.calculoNoSujeto();
          this.limpiarCamposProductos();
          continue;
        }

        if (
          this.tipo_doc_fiscal.nombre == "FAC	FACTURA DE CONSUMIDOR FINAL" ||
          this.tipo_doc_fiscal.tipo_documento_nombre
        ) {
          if (this.id_aseguradoras_retencion) {
            if (this.items_codigo[i].total >= 100) {
              var sin_iva = (this.items_codigos[i].total / 1.13).toFixed(2);
              this.retencion = (
                Number(this.retencion) +
                sin_iva * 0.01
              ).toFixed(2);
            }
          }
        } else {
          if (this.id_aseguradoras_retencion) {
            if (this.items_codigo[i].total >= 100) {
              this.retencion = (
                Number(this.retencion) +
                Number(this.items_codigo[i].total) * Number(0.01)
              ).toFixed(2);
            }
          }
        }

        if (this.id_aseguradoras_percepcion) {
          if (this.items_codigo[i].total >= 100) {
            this.percepcion =
              Number(this.percepcion) +
              (Number(this.items_codigo[i].total) * Number(0.01)).toFixed(2);
          }
        }

        this.sumarSubTotales(this.items_codigo[i].total);
        this.calculoGravadas(this.sub_total, this.descuento);

        if (
          this.tipo_doc_fiscal.nombre == "CCF	COMPROBANTE DE CREDITO FISCAL" ||
          this.tipo_doc_fiscal.nombre == "NCR	NOTA DE CREDITO"
        ) {
          this.calculoIva(this.gravadas);
        }

        this.limpiarCamposProductos();
      }
    },
    sumarSubTotales(v_total) {
      this.sub_total = parseFloat(
        Number(this.sub_total) + Number(v_total)
      ).toFixed(2);
    },
    sumarDescuentos() {
      if (this.descuento_prod > 0) {
        this.flag_descuento = true;
      }
      this.descuento = parseFloat(
        Number(this.descuento) + Number(this.monto_descuento)
      ).toFixed(2);
    },
    calculoGravadas(v_sub_total, v_descuento) {
      /*console.log(
                "Calculando Gravadas con Subtotal:",
                v_sub_total,
                "y Descuento:",
                v_descuento
            );*/
      this.gravadas = parseFloat(
        Number(v_sub_total) - Number(v_descuento)
      ).toFixed(2);
    },

    calculoIva(v_gravadas) {
      this.iva = parseFloat(Number(v_gravadas) * Number(0.13)).toFixed(2);
    },
    calculoExento(v_total) {
      //console.log("Calculando Exento con Total:", v_total);
      this.exentas = (Number(this.exentas) + Number(v_total)).toFixed(2);
      console.log("Exentas:", this.exentas);
    },

    calculoNoSujeto() {
      this.no_sujetas = (Number(this.no_sujetas) + Number(this.total)).toFixed(
        2
      );
    },

    editarFactura(item) {
      this.id_factura = item.id_factura;
      this.id_tipo_doc_fiscal = item.id_tipo_doc_fiscal;
      this.tipo_doc_fiscal = item;
      this.id_aseguradoras = item.id_aseguradoras;
      this.sucursal = item.id_sucursal;
      this.numero_control = item.numero_control;
      this.codigo_generacion = item.codigo_generacion;
      this.fecha = item.fecha;
      this.sello_recepcion = item.sello_recepcion;
      this.fecha_recepcion = item.fecha_recepcion;
      this.tipo_pago = item.tipo_pago;
      this.otros_cargos = item.otros_cargos;
      this.anticipo = item.anticipo;
      this.sub_total = item.sub_total;
      this.descuento = Number(this.descuento).toFixed(2); // Redondea a 2 decimales
      this.tipo_ingreso = item.tipo_ingreso;
      this.tipo_operacion = item.tipo_operacion;

      this.gravadas = item.gravadas;
      this.iva = item.iva;
      this.exentas = item.exentas;
      this.no_sujetas = item.no_sujetas;
      this.retencion = item.retencion;
      this.percepcion = item.percepcion;
      this.total_operacion = item.total_operacion;
      this.total_pagar = item.total_pagar;

      this.id_aseguradoras = item.id_aseguradoras;
      this.nombre = item.aseguradora_nombre;
      this.registro = item.aseguradora_registro;
      this.identificador = item.aseguradora_identificador;
      this.telefono = item.aseguradora_telefono;
      this.tipo = item.aseguradora_tipo;
      this.num_documento = item.aseguradora_documento;
      this.id_aseguradoras_exento = item.id_aseguradoras_exento;
      this.id_aseguradoras_retencion = item.id_aseguradoras_retencion;
      this.id_aseguradoras_percepcion = item.id_aseguradoras_percepcion;

      this.tipo_ingreso = item.tipo_ingreso;
      this.tipo_operacion = item.tipo_operacion;

      //this.listarDocSeleccionado();
      this.listarDetallesfactura();
      this.limpiarCamposProductos();

      this.flag_new_edit = 2;
      this.dialog = true;
    },

    eliminarDetalleFactura(item) {
      this.item_servicio_eliminar = item;
      this.dialog_eliminar_servicio = true;
    },
    confirmarEliminarDetalleFactura() {
      if (this.item_servicio_eliminar.id_detalle_factura > 0) {
        axios
          .delete(
            "api/DetallesFacturas/Eliminar/" +
              this.item_servicio_eliminar.id_detalle_factura
          )
          .then(() => {
            var index = this.items_codigo.findIndex(
              (item) =>
                item.id_detalle_factura ==
                this.item_servicio_eliminar.id_detalle_factura
            );
            if (index != -1) {
              this.items_codigo.splice(index, 1);
            }

            this.limpiarCalculosCabecera();

            for (var i = 0; i < this.items_codigo.length; i++) {
              //CALCULOS DE DESCUENTO
              this.sumarDescuentos();

              //CALCULOS DE EXENTO Y NO SUJETO
              if (this.aseguradora_exento) {
                this.calculoExento(this.items_codigo[i].total);
                this.limpiarCamposProductos();
                continue;
              }

              if (this.items_codigod[i].exento) {
                this.calculoExento(this.items_codigo[i].total);
                this.limpiarCamposProductos();
                continue;
              }
              if (this.items_codigo[i].no_sujeto) {
                this.calculoNoSujeto();
                this.limpiarCamposProductos();
                continue;
              }

              if (
                this.tipo_doc_fiscal.nombre ==
                  "FAC	FACTURA DE CONSUMIDOR FINAL" ||
                this.tipo_doc_fiscal.tipo_documento_nombre
              ) {
                if (this.id_aseguradoras_retencion) {
                  if (this.items_codigo[i].total >= 100) {
                    var sin_iva = (this.items_codigo[i].total / 1.13).toFixed(
                      2
                    );
                    this.retencion = (
                      Number(this.retencion) +
                      sin_iva * 0.01
                    ).toFixed(2);
                  }
                }
              } else {
                if (this.id_aseguradoras_retencion) {
                  if (this.items_codigo[i].total >= 100) {
                    this.retencion = (
                      Number(this.retencion) +
                      Number(this.items_codigo[i].total) * Number(0.01)
                    ).toFixed(2);
                  }
                }
              }

              if (this.id_aseguradoras_percepcion) {
                if (this.items_codigo[i].total >= 100) {
                  this.percepcion = (
                    Number(this.percepcion) +
                    Number(this.items_codigo[i].total) * Number(0.01)
                  ).toFixed(2);
                }
              }

              this.sumarSubTotales(this.items_codigo[i].total);
              this.calculoGravadas(this.sub_total, this.descuento);

              if (
                this.tipo_doc_fiscal.nombre ==
                  "CCF	COMPROBANTE DE CREDITO FISCAL" ||
                this.tipo_doc_fiscal.nombre == "NCR	NOTA DE CREDITO"
              ) {
                this.calculoIva(this.gravadas);
              }
            }
            this.dialog_eliminar_servicio = false;
            this.snackbar_text = "Servicio eliminado";
            this.snackbar = true;
          })
          .catch(() => {
            this.snackbar_text = "Servicio no se pudo eliminar";
            this.snackbar = true;
          });
      } else {
        var index = this.items_codigo.findIndex(
          (item) =>
            item.id_detalle_factura ==
            this.item_servicio_eliminar.id_detalle_factura
        );

        if (index != -1) {
          this.items_codigo.splice(index, 1);
        }

        this.limpiarCalculosCabecera();

        for (var i = 0; i < this.items_codigo.length; i++) {
          //CALCULOS DE DESCUENTO
          this.sumarDescuentos();

          //CALCULOS DE EXENTO Y NO SUJETO
          if (this.id_aseguradoras_exento) {
            this.calculoExento(this.items_codigo[i].total);
            this.limpiarCamposProductos();
            continue;
          }

          if (this.items_codigo[i].exento) {
            this.calculoExento(this.items_codigo[i].total);
            this.limpiarCamposProductos();
            continue;
          }
          if (this.items_codigos[i].no_sujeto) {
            this.calculoNoSujeto();
            this.limpiarCamposProductos();
            continue;
          }

          if (
            this.tipo_doc_fiscal.nombre == "FAC	FACTURA DE CONSUMIDOR FINAL" ||
            this.tipo_doc_fiscal.tipo_documento_nombre
          ) {
            if (this.id_aseguradoras_retencion) {
              if (this.items_codigo[i].total >= 100) {
                var sin_iva = (this.items_codigo[i].total / 1.13).toFixed(2);
                this.retencion = (
                  Number(this.retencion) +
                  sin_iva * 0.01
                ).toFixed(2);
              }
            }
          } else {
            if (this.id_aseguradoras_retencion) {
              if (this.items_codigo[i].total >= 100) {
                this.retencion = (
                  Number(this.retencion) +
                  Number(this.items_codigo[i].total) * Number(0.01)
                ).toFixed(2);
              }
            }
          }

          if (this.id_aseguradoras_percepcion) {
            if (this.total >= 100) {
              this.percepcion = (
                Number(this.percepcion) +
                Number(this.items_codigo[i].total) * Number(0.01)
              ).toFixed(2);
            }
          }

          this.sumarSubTotales(this.items_codigo[i].total);
          this.calculoGravadas(this.sub_total, this.descuento);

          if (
            this.tipo_doc_fiscal.nombre == "CCF	COMPROBANTE DE CREDITO FISCAL" ||
            this.tipo_doc_fiscal.nombre == "NCR	NOTA DE CREDITO"
          ) {
            this.calculoIva(this.gravadas);
          }
        }
        this.dialog_eliminar_servicio = false;
        this.snackbar_text = "Servicio eliminado";
        this.snackbar = true;
      }
    },
    editarProducto(item) {
      this.cantidad = item.cantidad;
      this.codigo = item.codigo;
      this.descripcion = item.descripcion;
      this.precio = item.precio;
      this.descuento = item.descuento;
      this.total = (this.precio * this.cantidad).toFixed(2);
    },

    crearfactura() {
      if (this.total_pagar == 0) {
        this.snackbar_text = "El total no debe de ir a cero";
        this.snackbar = true;
        return;
      }

      const detalleFactura = this.items_codigo.map((item) => ({
        cantidad: Number(item.cantidad),
        codigo: item.codigo,
        descripcion: item.descripcion,
        precio: parseFloat(item.precio),
        descuento: parseFloat(item.descuento),
        total: parseFloat(item.total),
        exento: item.exento,
        no_sujeto: item.no_sujeto,
      }));

      // Estructura de la factura
      const facturaData = {
        id_tipo_doc_fiscal: this.tipo_doc_fiscal.id_tipo_doc_fiscal,
        id_aseguradoras: this.id_aseguradoras,
        id_sucursal: Number(this.sucursal),
        numero_control: this.numero_control,
        codigo_generacion: this.codigo_generacion,
        fecha: this.formatDateISO(this.fecha),
        sello_recepcion: this.sello_recepcion || null,
        fecha_recepcion: this.fecha_recepcion
          ? this.formatDateISO(this.fecha_recepcion)
          : null,
        tipo_pago: this.tipo_pago,
        otros_cargos: parseFloat(this.otros_cargos),
        anticipo: parseFloat(this.anticipo),
        sub_total: parseFloat(this.sub_total),
        descuento: parseFloat(this.descuento),
        gravadas: parseFloat(this.gravadas),
        iva: parseFloat(this.iva),
        exentas: parseFloat(this.exentas),
        no_sujetas: parseFloat(this.no_sujetas),
        retencion: parseFloat(this.retencion),
        percepcion: parseFloat(this.percepcion),
        total_operacion: parseFloat(this.total_operacion),
        total_pagar: parseFloat(this.total_pagar),
        estatus: "GUARDADO",
        detalleFactura: detalleFactura,

        tipo_operacion: this.tipo_operacion,
        tipo_ingreso: this.tipo_ingreso,
      };

      this.loading_guardar = true;
      axios
        .post("api/Facturas/Crear", facturaData)
        .then((response) => {
          this.snackbar_text = "Registro creado con éxito";
          this.snackbar = true;
          this.loading_guardar = false;
          this.incrementarCorrelativoTipoDoc();
        })
        .catch(() => {
          this.snackbar_text = "Sucedió algún error";
          this.snackbar = true;
          this.loading_guardar = false;
        });
    },

    actualizarfactura() {
      axios
        .put("api/Facturas/Actualizar", {
          id_factura: this.id_factura,
          id_tipo_doc_fiscal: this.tipo_doc_fiscal.id_tipo_doc_fiscal,
          id_aseguradoras: this.id_aseguradoras,
          id_sucursal: this.sucursal,
          numero_control: this.numero_control,
          codigo_generacion: this.codigo_generacion,
          fecha: this.fecha,
          sello_recepcion: this.sello_recepcion,
          fecha_recepcion: this.fecha_recepcion,
          tipo_pago: this.tipo_pago,
          otros_cargos: this.otros_cargos,
          anticipo: this.anticipo,
          sub_total: this.sub_total,
          descuento: this.descuento,
          gravadas: this.gravadas,
          iva: this.iva,
          exentas: this.exentas,
          no_sujetas: this.no_sujetas,
          retencion: this.retencion,
          percepcion: this.percepcion,
          total_operacion: this.total_operacion,
          total_pagar: this.total_pagar,
          estatus: "GUARDADO",
          tipo_operacion,
          tipo_ingreso,

          detalleFactura: this.items_codigo,

          tipo_ingreso: this.tipo_ingreso,
          tipo_operacion: this.tipo_operacion,
        })
        .then(() => {
          this.snackbar_text = "Registro actualizado con exito";
          this.snackbar = true;
          this.cerrarDialog();
          this.listarFacturas();
        })
        .catch(() => {
          this.snackbar_text = "Sucedio algún error";
          this.snackbar = true;
        });
    },
    confirmarAnularDocumento() {
      this.dialog_confirmar_anular = true;
    },
    anularFactura() {
      axios
        .post("api/ServicioRecepciones/FirmarDocumento2", {
          cod_emp: this.$store.state.usuario.idUsuario,
          tipoDte: "INV",
          idFactura: this.id_factura,
          ambiente: "00",
        })
        .then(() => {
          this.snackbar_text = "Solicitud enviada";
          this.snackbar = true;
          this.listarFacturas();
        })
        .catch(() => {
          this.snackbar_text = "Error de solicitud";
          this.snackbar = true;
        });
    },
    listarDetallesfactura() {
      axios
        .get("api/Detallesfacturas/Listar/" + this.id_factura)
        .then((response) => {
          //console.log(response.data);
          this.items_codigo = response.data;
        });
    },
    listarTipoOperacion() {
      axios.get("api/TipoOperacion/Listar").then((response) => {
        this.items_tipo_operacion = response.data;
      });
    },
    listarTipoIngreso() {
      axios.get("api/TipoIngreso/Listar").then((response) => {
        this.items_tipo_ingreso = response.data;
      });
    },
    limpiarCamposProductos() {
      this.cantidad = 1;
      this.codigo = "";
      this.descripcion = "";
      this.precio = 0;
      this.descuento_prod = 0;
      this.total = 0;
    },
    limpiarCalculosCabecera() {
      this.otros_cargos = 0;
      this.anticipo = 0;
      this.sub_total = 0;
      this.descuento = 0;
      this.gravadas = 0;
      this.iva = 0;
      this.exentas = 0;
      this.no_sujetas = 0;
      this.retencion = 0;
      this.percepcion = 0;
      this.total_operacion = 0;
      this.total_pagar = 0;
    },
    cerrarDialog() {
      this.dialog = false;
      this.limpiar();
    },
    limpiar() {
      this.id_tipo_doc_fiscal = 0;
      this.numero_control = "DTE-00-00000000-000000000000000";
      this.fecha = "";
      this.codigo_generacion = "";
      this.sello_recepcion = "";
      this.fecha_recepcion = "";
      this.tipo_doc_fiscal = "";
      this.tipo_pago = "CONTADO";
      this.id_aseguradoras = 0;
      this.id_aseguradoras = "";
      this.num_documento = "";
      this.nombre = "";
      this.registro = "";
      this.identificador = "";
      this.telefono = "";
      this.tipo = "";
      this.id_aseguradoras_exento = "";
      this.id_aseguradoras_retencion = "";
      this.id_aseguradoras_percepcion = "";
      this.id_aseguradoras_tamaño = "";
      this.cantidad = 1;
      this.codigo = "";
      this.descripcion = "";
      this.precio = 0;
      this.descuento = 0;
      this.total = 0;
      this.items_codigo = [];
      this.otros_cargos = 0;
      this.anticipo = 0;
      this.sub_total = 0;
      this.gravadas = 0;
      this.iva = 0;
      this.exentas = 0;
      this.no_sujetas = 0;
      this.retencion = 0;
      this.percepcion = 0;
      this.total_operacion = 0;
      this.total_pagar = 0;
      this.id_detalle_factura = 0;
      this.cont_prod = 0;
      this.flag_new_edit = 1;

      this.items_tipo_doc_ncr_ndb = [];

      this.tipo_operacion = "";
      this.tipo_ingreso = "";
    },

    listarDocSeleccionado() {
      var formato = "DTE-";
      var formato_ceros = "-00000000-000000000000000";
      var correlativo_length = "";
      var id = 0;

      if (this.tipo_doc_fiscal.id_tipo_doc_fiscal != undefined) {
        id = this.tipo_doc_fiscal.id_tipo_doc_fiscal;
      } else {
        id = this.id_tipo_doc_fiscal;
      }

      axios
        .get("api/ConfiFacturas/ListarSeleccionado/" + id)
        .then((response) => {
          this.tipo_doc_fiscal = response.data;

          if (this.tipo_doc_fiscal.correlativo == null) {
            console.error("El correlativo recibido es nulo");
            return;
          }

          correlativo_length = response.data.correlativo.toString().length;

          formato_ceros = formato_ceros.substring(
            0,
            formato_ceros.length - correlativo_length
          );

          this.numero_control = formato
            .concat(response.data.cod_mh_doc_fiscal)
            .concat(formato_ceros)
            .concat(response.data.correlativo);
        })
        .catch((error) => {
          console.error("Error al listar documento seleccionado:", error);
        });
    },

    incrementarCorrelativoTipoDoc() {
      if (
        this.tipo_doc_fiscal.correlativo == null ||
        isNaN(this.tipo_doc_fiscal.correlativo)
      ) {
        console.error(
          "El valor del correlativo es nulo o no es un número válido."
        );
        return;
      }

      axios
        .put("api/ConfiFacturas/Incrementar", {
          id_confi_factura: this.tipo_doc_fiscal.id_confi_factura,
          valor: Number(this.tipo_doc_fiscal.correlativo) + 1,
        })
        .then(() => {
          this.cerrarDialog();
          this.listarFacturas();
        });
    },

    formatoFecha(item) {
      return settings.metodos.formatoFechaHora(item);
    },

    listarCCF() {
      if (this.id_aseguradoras <= 0) {
        this.snackbar_text = "Debe de seleccionar un asegurador primero.";
        this.snackbar = true;
        return;
      }

      axios
        .get("api/Facturas/ListarParaNcrNdb/" + this.id_aseguradoras)
        .then((response) => {
          if (Array.isArray(response.data)) {
            this.items_ccf = response.data;
            // condición si no hay factras
            if (this.items_ccf.length === 0) {
              this.snackbar_text = "No se encontraron facturas.";
              this.snackbar = true;
            }
          } else {
            console.error("La respuesta no es un arreglo:", response.data);
            this.items_ccf = [];
            this.snackbar_text = "Ocurrió un error al obtener los datos.";
            this.snackbar = true;
          }
        })
        .catch((error) => {
          console.error("Error al obtener facturas para NCR/NDB:", error);

          if (error.response && error.response.data) {
            const contentType = error.response.headers["content-type"];
            if (contentType && contentType.includes("text/html")) {
              this.snackbar_text =
                "Error en el servidor: se devolvió una página HTML.";
            } else {
              this.snackbar_text =
                "Error al obtener las facturas: " +
                  error.response.data.message || "Error desconocido.";
            }
          } else {
            this.snackbar_text =
              "Error de red: no se pudo conectar al servidor.";
          }
          this.snackbar = true;
        });
    },

    listarServiciosDocNCR(item) {
      var format_date_now = settings.metodos.formatoFecha(new Date());
      var format_date_ccf = settings.metodos.formatoFecha(item.fecha);

      var date_now = new Date(format_date_now);
      var date_ccf = new Date(format_date_ccf);

      var milliseconds = Math.abs(date_now - date_ccf);
      var days = Math.ceil(milliseconds / (1000 * 60 * 60 * 24));
      if (days > 120) {
        this.snackbar_text = "Ya han pasado mas de 120 días para este doc.";
        this.snackbar = true;
        return;
      }

      axios
        .get("api/DetallesFacturas/Listar/" + item.id_factura)
        .then((response) => {
          this.items_detalles_fac = response.data;

          this.dialog_tabla_detalles_fac = true;
        });
    },
    agregarServiciosDocNCR() {
      this.limpiarCalculosCabecera();
      for (var i = 0; i < this.items_codigo.length; i++) {
        if (this.id_aseguradoras_exento) {
          this.calculoExento(this.items_codigo[i].total);
          return;
        }

        if (this.items_codigo[i].exento) {
          this.calculoExento(this.items_codigo[i].total);
          continue;
        }
        if (this.items_codigo[i].no_sujeto) {
          this.calculoNoSujeto();
          continue;
        }

        //calculo sub total
        this.sub_total = (
          Number(this.sub_total) + Number(this.items_codigo[i].total)
        ).toFixed(2);

        //calculo descuento
        this.monto_descuento = 0;
        this.monto_descuento =
          Number(this.items_codigo[i].cantidad) *
          Number(this.items_codigo[i].precio) *
          (Number(this.items_codigo[i].descuento) / 100);
        this.descuento = Number(this.descuento) + Number(this.monto_descuento);

        //calculo gravadas
        this.gravadas = (
          Number(this.sub_total) - Number(this.descuento)
        ).toFixed(2);

        //calculo iva
        if (
          this.tipo_doc_fiscal.nombre == "CCF	COMPROBANTE DE CREDITO FISCAL" ||
          this.tipo_doc_fiscal.nombre == "NCR	NOTA DE CREDITO"
        ) {
          this.calculoIva(this.gravadas);
        }

        //retencion
        if (this.id_aseguradoras_retencion) {
          if (element.total >= 100) {
            this.retencion = (Number(element.total) * Number(0.01)).toFixed(2);
          }
        }

        //percepcion
        if (this.id_aseguradoras_percepcion) {
          if (element.total >= 100) {
            this.percepcion = (Number(element.total) * Number(0.01)).toFixed(2);
          }
        }
      }

      this.dialog_tabla_detalles_fac = false;
    },
    colorTablaCCF(item) {
      if (this.flag_new_edit == 1) {
        return "";
      } else {
        var index = this.lista_nrc_ndb_guardados.findIndex(
          (i) => i.id_factura_relacionado == item.id_factura
        );
        if (index > -1) {
          return "green";
        }
      }
    },
    colorTablaDetalles(item) {
      if (this.flag_new_edit == 1) {
        return "white";
      } else {
        var index = this.items_codigo.findIndex(
          (i) => i.id_detalle_factura_ncr == item.id_detalle_factura
        );
        if (index > -1) {
          return "green";
        }
      }
    },
    fechaLimiteDebitoCredito(obj) {
      var format_date_now = settings.metodos.formatoFecha(new Date());
      var format_date_ccf = settings.metodos.formatoFecha(obj.fecha);

      var date_now = new Date(format_date_now);
      var date_ccf = new Date(format_date_ccf);

      var milliseconds = Math.abs(date_now - date_ccf);
      var days = Math.ceil(milliseconds / (1000 * 60 * 60 * 24));
      if (days > 90) {
        return true;
      } else {
        return false;
      }
    },
  },
};
</script>

<style>
#myPDF {
  width: 100%;
  height: 100%;
}
</style>
